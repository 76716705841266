@import url("https://fonts.googleapis.com/css?family=Georama:300,400,500,700&display=block");
:root {
}
html {
  font-size: calc(15px + (18 - 15) * ((100vw - 1601px) / (1920 - 1601)));
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}
html.x1 {
  font-size: calc(
    (15px + (18 - 15) * ((100vw - 1601px) / (1920 - 1601))) * 1.5
  );
}
html.x2 {
  font-size: calc((15px + (18 - 15) * ((100vw - 1601px) / (1920 - 1601))) * 2);
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  font-size: 100%;
  margin: 0;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
sup {
  font-size: 0.5em;
}
body > iframe {
  position: absolute;
  overflow: hidden;
  height: 0;
  width: 0;
}
a[href^="tel"] {
  cursor: default;
}
address {
  font-style: normal;
}
img {
  max-width: 100%;
}
figure,
blockquote {
  margin: 0;
}
video {
  display: block;
  max-width: 100%;
}
video[poster] {
  object-fit: cover;
}
.rlt {
  position: relative;
}
.blk {
  display: block;
}
.hdn {
  overflow: hidden;
}
.psu-bfr,
.psu-aft,
.bg-bx {
  z-index: 1;
  position: relative;
}
.bg-bx:not([class*="flx"]) {
  display: block;
}
.psu-bfr::before,
.psu-aft::after,
.bg-bx::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}
.bg-image .bg-bx::before,
.bg-inset .bg-bx::before {
  opacity: 1;
}
.m_ato {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.disabled,
[disabled] {
  pointer-events: none;
  opacity: 0.7;
}
[aria-hidden="true"] {
  visibility: hidden;
}
section header:empty {
  display: none;
}
fieldset {
  min-inline-size: auto;
}
fieldset li.w-ato {
  width: auto !important;
}
div > br:first-child:last-child:only-child,
header > br:first-child:last-child:only-child {
  display: none;
}
[data-role="btn"] {
  cursor: pointer;
}
.media-large {
  margin-top: 2em;
  margin-bottom: 2em;
}
svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}
svg [style*="stroke-width"],
svg [stroke-width] {
  stroke: currentColor;
}
svg [style*="stroke-width"]:not([fill]),
svg [stroke-width]:not([fill]) {
  fill: none;
}
.no-transitions body *,
.no-transitions body *:before,
.no-transitions body *:after {
  transition: none !important;
}
@media (prefers-reduced-motion: reduce) {
  body *,
  body *:before,
  body *:after {
    transition: none !important;
  }
}
[data-src],
[data-bg],
[data-src].loading,
[data-bg].loading {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
img,
video,
source {
  filter: none;
  -webkit-filter: none;
  transition: filter 0.3s ease 0.3s;
}
img[data-parallax],
video[data-parallax],
source[data-parallax] {
  transition: none;
}
svg use {
  opacity: 0;
}
svg * {
  pointer-events: none;
}
svg use:empty {
  display: none;
}
section:not(.vsbl) {
  position: relative;
  overflow: hidden;
}
[class*="mn_"] {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding-left: calc(
    (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
  );
  padding-right: calc(
    (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
  );
}
.mn_wd {
  max-width: calc(
    91.333rem + calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) *
      2
  );
}
.mn_tn {
  max-width: calc(
    64.41rem + calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) *
      2
  );
}
.sd-zn > * + *,
.cnt-zn > * + * {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 2
  );
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
h1 > b,
h1 > strong,
section header > *:first-child:not(a) > b,
section header > *:first-child:not(a) > strong {
  display: inline-block;
}
section header [class*="fnt_t"] + p,
section header [class*="fnt_t"] + [class*="fnt_t"] {
  margin-top: 0.65rem;
}
.cnt-stl h1 {
  margin-bottom: 0.65rem;
}
.cnt-stl h2,
.cnt-stl h3,
.cnt-stl h4,
.cnt-stl h5,
.cnt-stl h6 {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.cnt-stl h1 + h2,
.cnt-stl h1 + h3,
.cnt-stl h1 + h4,
.cnt-stl h2 + h3,
.cnt-stl h2 + h4 {
  margin-top: 0.65rem;
}
a {
  text-decoration: none;
  transition: color 0.3s ease 0s;
}
button {
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  background-color: transparent;
}
.dk-lg,
.lt-lg {
  display: none;
}
.lt-bg .dk-lg {
  display: block;
}
.dk-bg .lt-lg {
  display: block;
}
img {
  display: block;
}
img[style*="background-image"] {
  background-position: center;
  background-repeat: no-repeat;
}
img[style*=".png"] {
  background-size: contain;
}
img[style*=".jpg"] {
  background-size: cover;
}
.bg-wrp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
picture {
  display: block;
  position: relative;
}
picture img {
  width: 100%;
}
picture img[src*=".jpg"],
picture img.static-map {
  object-fit: cover;
}
picture img[src*=".png"]:not(.static-map) {
  object-fit: contain;
}
picture.img-bg img[src*=".png"] {
  max-width: 100%;
  max-height: 100%;
  min-width: 0 !important;
  min-height: 0 !important;
}
picture img[style*="background-image"] {
  width: 100% !important;
  height: 100% !important;
}
[class*="pd-h-"] {
  width: 100%;
  display: block;
}
[class*="pd-h-"]::after {
  content: "";
  display: block;
  width: 100%;
}
[class*="pd-h-"][class*="-50"]::after {
  padding-bottom: 50%;
}
[class*="pd-h-"][class*="-56"]::after {
  padding-bottom: 56.25%;
}
[class*="pd-h-"][class*="-75"]::after {
  padding-bottom: 75%;
}
[class*="pd-h-"][class*="-100"]::after {
  padding-bottom: 100%;
}
[class*="pd-h-"][class*="-125"]::after {
  padding-bottom: 125%;
}
.img {
  position: relative;
}
.img img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.img-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}
.img-bg img {
  position: relative;
  left: 50%;
  top: 50%;
  width: auto;
  height: auto;
  min-width: calc(100% + 2px);
  min-height: calc(100% + 2px);
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.img-bg img[style*="background-image"] {
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  transform: none !important;
}
aside:not(.bg-image):not(.bg-inset) > .img-bg,
section:not(.bg-image):not(.bg-inset) > .img-bg,
aside:not(.bg-image):not(.bg-inset) > .bg-wrp,
section:not(.bg-image):not(.bg-inset) > .bg-wrp {
  display: none;
}
.media-left {
  float: left;
  margin: 1em 1em 1em 0;
}
.media-right {
  float: right;
  margin: 1em 0 1em 1em;
}
.gbl-icn {
  position: relative;
  padding: 0.53em;
  border-radius: 50%;
  transition: color 0.3s ease 0s;
}
.gbl-icn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  border-color: currentColor;
  opacity: 1;
  border-style: solid;
  border-width: 0px;
  border-radius: 50%;
  transition: border-color 0.3s ease 0s;
}
.so-lk svg {
  font-size: 1.17em;
}
.py-lk svg {
  font-size: 1.67em;
}
.cnt-stl > *:first-child {
  margin-top: 0;
}
.cnt-stl > *:last-child {
  margin-bottom: 0;
}
.cnt-stl .media-full {
  margin: 1.5rem auto;
}
.cnt-stl .image-style-side {
  float: right;
  margin-left: 1.5rem;
  max-width: 50%;
}
.ta_l {
  text-align: left;
}
.ta_c {
  text-align: center;
}
.ta_c svg {
  margin-left: auto;
  margin-right: auto;
}
.ta_r {
  text-align: right;
}
.ta_r svg {
  margin-left: auto;
  margin-right: 0;
}
.ta_c img {
  margin-left: auto;
  margin-right: auto;
}
.ta_r img {
  margin-right: 0;
  margin-left: auto;
}
@media screen and (min-width: 801px) {
  .splt-lst ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .splt-lst ul li {
    width: 48%;
  }
}
@media screen and (min-width: 1921px) {
  html {
    position: relative;
    z-index: 1;
    font-size: calc(18px + (22 - 18) * ((100vw - 1921px) / (3840 - 1921)));
  }
  html.x1 {
    font-size: calc(
      (18px + (22 - 18) * ((100vw - 1921px) / (3840 - 1921))) * 1.5
    );
  }
  html.x2 {
    font-size: calc(
      (18px + (22 - 18) * ((100vw - 1921px) / (3840 - 1921))) * 2
    );
  }
  html body {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1600px) {
  html {
    font-size: calc(15px + (18 - 15) * ((100vw - 1281px) / (1600 - 1281)));
  }
  html.x1 {
    font-size: calc(
      (15px + (18 - 15) * ((100vw - 1281px) / (1600 - 1281))) * 1.5
    );
  }
  html.x2 {
    font-size: calc(
      (15px + (18 - 15) * ((100vw - 1281px) / (1600 - 1281))) * 2
    );
  }
  html main br {
    display: none;
  }
  .hd-1600 {
    display: none !important;
  }
}
@media screen and (max-width: 1440px) {
  .hd-1440 {
    display: none !important;
  }
}
@media screen and (max-width: 1280px) {
  html {
    font-size: calc(14px + (17 - 14) * ((100vw - 801px) / (1280 - 801)));
  }
  html.x1 {
    font-size: calc(
      (14px + (17 - 14) * ((100vw - 801px) / (1280 - 801))) * 1.5
    );
  }
  html.x2 {
    font-size: calc((14px + (17 - 14) * ((100vw - 801px) / (1280 - 801))) * 2);
  }
  .hd-1280 {
    display: none !important;
  }
  .ta_c-1280 {
    text-align: center !important;
  }
  img.ta_c-1280,
  .ta_c-1280 img,
  svg.ta_c-1280,
  .ta_c-1280 svg {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-1280 [class*="flx"],
  [class*="flx"].ta_c-1280 {
    justify-content: center;
  }
}
@media screen and (max-width: 1024px) {
  [class*="mn_"] {
    padding-left: 0;
    padding-right: 0;
  }
  .rsp_pd {
    padding: calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320)))
      calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) !important;
  }
  .rsp_pd-v {
    padding-top: calc(
      (50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))
    ) !important;
    padding-bottom: calc(
      (50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))
    ) !important;
  }
  .rsp_pd-h {
    padding-left: calc(
      (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
    ) !important;
    padding-right: calc(
      (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
    ) !important;
  }
  .rsp_mrg-0 {
    margin: 0 !important;
  }
  .lt-bg.rsp_opn-bt + .lt-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp,
  .lt-bg.rsp_opn-bt + form .lt-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp,
  .dk-bg.rsp_opn-bt + .dk-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp,
  .dk-bg.rsp_opn-bt + form .dk-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp {
    padding-top: 0 !important;
  }
  section[class*="pd_v"]:not(.pd-sty) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  section[class*="pd_v"].pd-sty {
    padding-bottom: 0 !important;
  }
  .hd-1024 {
    display: none !important;
  }
  .ta_c-1024 {
    text-align: center !important;
  }
  img.ta_c-1024,
  .ta_c-1024 img,
  svg.ta_c-1024,
  .ta_c-1024 svg {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-1024 [class*="flx"],
  [class*="flx"].ta_c-1024 {
    justify-content: center;
  }
}
@media screen and (max-width: 800px) {
  html {
    font-size: calc(16px + (18 - 16) * ((100vw - 501px) / (800 - 501)));
  }
  html.x1 {
    font-size: calc((16px + (18 - 16) * ((100vw - 501px) / (800 - 501))) * 1.5);
  }
  html.x2 {
    font-size: calc((16px + (18 - 16) * ((100vw - 501px) / (800 - 501))) * 2);
  }
  .hd-800 {
    display: none !important;
  }
  .ta_c-800 {
    text-align: center !important;
  }
  img.ta_c-800,
  .ta_c-800 img,
  svg.ta_c-800,
  .ta_c-800 svg {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-800 [class*="flx"],
  [class*="flx"].ta_c-800 {
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  html {
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (500 - 320)));
  }
  html.x1 {
    font-size: calc((15px + (17 - 15) * ((100vw - 320px) / (500 - 320))) * 1.5);
  }
  html.x2 {
    font-size: calc((15px + (17 - 15) * ((100vw - 320px) / (500 - 320))) * 2);
  }
  .hd-500 {
    display: none !important;
  }
  .ta_c-500 {
    text-align: center !important;
  }
  img.ta_c-500,
  .ta_c-500 img,
  svg.ta_c-500,
  .ta_c-500 svg {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-500 [class*="flx"],
  [class*="flx"].ta_c-500 {
    justify-content: center;
  }
}
@media screen and (max-width: 380px) {
  .hd-380 {
    display: none !important;
  }
}
.ta_l {
  text-align: left;
}
.ta_c {
  text-align: center;
}
.ta_c svg:not(.g-sa) {
  margin-left: auto;
  margin-right: auto;
}
.ta_r {
  text-align: right;
}
.ta_r svg:not(.g-sa) {
  margin-left: auto;
  margin-right: 0;
}
.ta_c img {
  margin-left: auto;
  margin-right: auto;
}
.ta_r img {
  margin-right: 0;
  margin-left: auto;
}
.bg-bx:not([class*="flx"]) {
  display: block;
}
.bg-bx.brd {
  border-style: solid;
  border-width: 1px;
}
@media screen and (min-width: 1025px) {
  .f_ud.mrg,
  .f_du.mrg {
    margin-top: 3rem;
  }
  .f_ud:not(.f_rev) > *:first-child,
  .f_du:not(.f_rev) > *:last-child,
  .f_rev.f_ud > *:last-child,
  .f_rev.f_du > *:first-child {
    margin-bottom: 3rem;
  }
  .f_du:not(.f_rev) > *:first-child,
  .f_ud:not(.f_rev) > *:last-child,
  .f_rev.f_du > *:last-child,
  .f_rev.f_ud > *:first-child {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 1600px) {
  .ta_c-1600 {
    text-align: center !important;
  }
  img.ta_c-1600,
  .ta_c-1600 img,
  svg.ta_c-1600,
  .ta_c-1600 svg:not(.g-sa) {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-1600 [class*="flx"],
  [class*="flx"].ta_c-1600 {
    justify-content: center;
  }
}
@media screen and (max-width: 1440px) {
  .ta_c-1440 {
    text-align: center !important;
  }
  img.ta_c-1440,
  .ta_c-1440 img,
  svg.ta_c-1440,
  .ta_c-1440 svg:not(.g-sa) {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-1440 [class*="flx"],
  [class*="flx"].ta_c-1440 {
    justify-content: center;
  }
}
@media screen and (max-width: 1280px) {
  .ta_c-1280 {
    text-align: center !important;
  }
  img.ta_c-1280,
  .ta_c-1280 img,
  svg.ta_c-1280,
  .ta_c-1280 svg:not(.g-sa) {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-1280 [class*="flx"],
  [class*="flx"].ta_c-1280 {
    justify-content: center;
  }
}
@media screen and (max-width: 1024px) {
  .ta_c-1024 {
    text-align: center !important;
  }
  img.ta_c-1024,
  .ta_c-1024 img,
  svg.ta_c-1024,
  .ta_c-1024 svg:not(.g-sa) {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-1024 [class*="flx"],
  [class*="flx"].ta_c-1024 {
    justify-content: center;
  }
}
@media screen and (max-width: 800px) {
  .ta_c-800 {
    text-align: center !important;
  }
  img.ta_c-800,
  .ta_c-800 img,
  svg.ta_c-800,
  .ta_c-800 svg:not(.g-sa) {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-800 [class*="flx"],
  [class*="flx"].ta_c-800 {
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .ta_c-500 {
    text-align: center !important;
  }
  img.ta_c-500,
  .ta_c-500 img,
  svg.ta_c-500,
  .ta_c-500 svg:not(.g-sa) {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-500 [class*="flx"],
  [class*="flx"].ta_c-500 {
    justify-content: center;
  }
}
@media screen and (max-width: 380px) {
  .ta_c-380 {
    text-align: center !important;
  }
  img.ta_c-380,
  .ta_c-380 img,
  svg.ta_c-380,
  .ta_c-380 svg:not(.g-sa) {
    margin-left: auto;
    margin-right: auto;
  }
  .ta_c-380 [class*="flx"],
  [class*="flx"].ta_c-380 {
    justify-content: center;
  }
}
:root {
}
.pd_v {
  padding-top: calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(
    (50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))
  );
}
.pd_v-10 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.2
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.2
  );
}
.pd_v-20 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.4
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.4
  );
}
.pd_v-30 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.6
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.6
  );
}
.pd_v-40 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.8
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 0.8
  );
}
.pd_v-60 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.2
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.2
  );
}
.pd_v-70 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.4
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.4
  );
}
.pd_v-80 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.6
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.6
  );
}
.pd_v-90 {
  padding-top: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.8
  );
  padding-bottom: calc(
    calc((50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))) * 1.8
  );
}
.pd_h {
  padding-left: calc(
    (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
  );
  padding-right: calc(
    (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
  );
}
.pd_h-10 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.2
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.2
  );
}
.pd_h-20 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.4
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.4
  );
}
.pd_h-30 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.6
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.6
  );
}
.pd_h-40 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.8
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.8
  );
}
.pd_h-60 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.2
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.2
  );
}
.pd_h-70 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.4
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.4
  );
}
.pd_h-80 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.6
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.6
  );
}
.pd_h-90 {
  padding-left: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.8
  );
  padding-right: calc(
    calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 1.8
  );
}
.mrg_tp {
  margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
}
.mrg_tp-0 {
  margin-top: 0;
}
.mrg_tp-10 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.2
  );
}
.mrg_tp-20 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.4
  );
}
.mrg_tp-30 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.6
  );
}
.mrg_tp-40 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.8
  );
}
.mrg_tp-60 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.2
  );
}
.mrg_tp-70 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.4
  );
}
.mrg_tp-80 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.6
  );
}
.mrg_tp-90 {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.8
  );
}
.mrg_bt {
  margin-bottom: calc(
    (30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))
  );
}
.mrg_bt-0 {
  margin-bottom: 0;
}
.mrg_bt-10 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.2
  );
}
.mrg_bt-20 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.4
  );
}
.mrg_bt-30 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.6
  );
}
.mrg_bt-40 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.8
  );
}
.mrg_bt-60 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.2
  );
}
.mrg_bt-70 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.4
  );
}
.mrg_bt-80 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.6
  );
}
.mrg_bt-90 {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.8
  );
}
.pd_tp {
  padding-top: 1rem;
}
.pd_tp-0 {
  padding-top: 0;
}
.pd_tp-10 {
  padding-top: calc(1rem * 0.2);
}
.pd_tp-20 {
  padding-top: calc(1rem * 0.4);
}
.pd_tp-30 {
  padding-top: calc(1rem * 0.6);
}
.pd_tp-40 {
  padding-top: calc(1rem * 0.8);
}
.pd_tp-60 {
  padding-top: calc(1rem * 1.2);
}
.pd_tp-70 {
  padding-top: calc(1rem * 1.4);
}
.pd_tp-80 {
  padding-top: calc(1rem * 1.6);
}
.pd_tp-90 {
  padding-top: calc(1rem * 1.8);
}
.pd_bt {
  padding-bottom: 1rem;
}
.pd_bt-0 {
  padding-bottom: 0;
}
.pd_bt-10 {
  padding-bottom: calc(1rem * 0.2);
}
.pd_bt-20 {
  padding-bottom: calc(1rem * 0.4);
}
.pd_bt-30 {
  padding-bottom: calc(1rem * 0.6);
}
.pd_bt-40 {
  padding-bottom: calc(1rem * 0.8);
}
.pd_bt-60 {
  padding-bottom: calc(1rem * 1.2);
}
.pd_bt-70 {
  padding-bottom: calc(1rem * 1.4);
}
.pd_bt-80 {
  padding-bottom: calc(1rem * 1.6);
}
.pd_bt-90 {
  padding-bottom: calc(1rem * 1.8);
}
.mrg_lt {
  margin-left: 1rem;
}
.mrg_lt-10 {
  margin-left: calc(1rem * 0.2);
}
.mrg_lt-20 {
  margin-left: calc(1rem * 0.4);
}
.mrg_lt-30 {
  margin-left: calc(1rem * 0.6);
}
.mrg_lt-40 {
  margin-left: calc(1rem * 0.8);
}
.mrg_lt-60 {
  margin-left: calc(1rem * 1.2);
}
.mrg_lt-70 {
  margin-left: calc(1rem * 1.4);
}
.mrg_lt-80 {
  margin-left: calc(1rem * 1.6);
}
.mrg_lt-90 {
  margin-left: calc(1rem * 1.8);
}
.mrg_rt {
  margin-right: 1rem;
}
.mrg_rt-10 {
  margin-right: calc(1rem * 0.2);
}
.mrg_rt-20 {
  margin-right: calc(1rem * 0.4);
}
.mrg_rt-30 {
  margin-right: calc(1rem * 0.6);
}
.mrg_rt-40 {
  margin-right: calc(1rem * 0.8);
}
.mrg_rt-60 {
  margin-right: calc(1rem * 1.2);
}
.mrg_rt-70 {
  margin-right: calc(1rem * 1.4);
}
.mrg_rt-80 {
  margin-right: calc(1rem * 1.6);
}
.mrg_rt-90 {
  margin-right: calc(1rem * 1.8);
}
@media screen and (max-width: 1600px) {
  [class*="flx"][class*="-blk-1600"][class*="-mgd"] > * + * {
    margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  [class*="flx"][class*="-blk-1600"][class*="-mgd-l"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.5
    );
  }
  [class*="flx"][class*="-blk-1600"][class*="-mgd-b"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.5
    );
  }
}
@media screen and (max-width: 1440px) {
  [class*="flx"][class*="-blk-1440"][class*="-mgd"] > * + * {
    margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  [class*="flx"][class*="-blk-1440"][class*="-mgd-l"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.5
    );
  }
  [class*="flx"][class*="-blk-1440"][class*="-mgd-b"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.5
    );
  }
}
@media screen and (max-width: 1280px) {
  [class*="flx"][class*="-blk-1280"][class*="-mgd"] > * + * {
    margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  [class*="flx"][class*="-blk-1280"][class*="-mgd-l"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.5
    );
  }
  [class*="flx"][class*="-blk-1280"][class*="-mgd-b"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.5
    );
  }
}
@media screen and (max-width: 1024px) {
  [class*="flx"][class*="-blk-1024"][class*="-mgd"] > * + *,
  [class*="flx"][class*="-ato-rsp"][class*="-mgd"] > * + * {
    margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  [class*="flx"][class*="-blk-1024"][class*="-mgd-l"] > * + *,
  [class*="flx"][class*="-ato-rsp"][class*="-mgd-l"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.5
    );
  }
  [class*="flx"][class*="-blk-1024"][class*="-mgd-b"] > * + *,
  [class*="flx"][class*="-ato-rsp"][class*="-mgd-b"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.5
    );
  }
}
@media screen and (max-width: 800px) {
  .pd_h-10 {
    padding-left: calc(
      calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.4
    );
    padding-right: calc(
      calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.4
    );
  }
  [class*="flx"][class*="-blk-800"][class*="-mgd"] > * + * {
    margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  [class*="flx"][class*="-blk-800"][class*="-mgd-l"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.5
    );
  }
  [class*="flx"][class*="-blk-800"][class*="-mgd-b"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.5
    );
  }
}
@media screen and (max-width: 500px) {
  .pd_h-10,
  .pd_h-20 {
    padding-left: calc(
      calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.6
    );
    padding-right: calc(
      calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))) * 0.6
    );
  }
  .pd_tp-10 {
    padding-top: calc(1rem * 0.4);
  }
  [class*="flx"][class*="-blk-500"][class*="-mgd"] > * + * {
    margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  [class*="flx"][class*="-blk-500"][class*="-mgd-l"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.5
    );
  }
  [class*="flx"][class*="-blk-500"][class*="-mgd-b"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.5
    );
  }
}
@media screen and (max-width: 380px) {
  [class*="flx"][class*="-blk-380"][class*="-mgd"] > * + * {
    margin-top: calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
  [class*="flx"][class*="-blk-380"][class*="-mgd-l"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.5
    );
  }
  [class*="flx"][class*="-blk-380"][class*="-mgd-b"] > * + * {
    margin-top: calc(
      calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 1.5
    );
  }
}
[class*="flx"] {
  align-items: stretch;
  align-content: space-around;
  display: flex;
}
[class*="flx"] .fit {
  flex: 0 0 auto;
}
[class*="flx"] .ato {
  flex: 1 1 auto;
}
[class*="flx"] .str {
  align-self: stretch;
}
.f_iln {
  display: inline-flex;
}
.f_c {
  justify-content: center;
}
.f_r {
  justify-content: flex-end;
}
.f_l {
  justify-content: flex-start;
}
.f_sb {
  justify-content: space-between;
}
.f_sa {
  justify-content: space-around;
}
.f_m {
  align-items: center;
}
.f_t {
  align-items: flex-start;
}
.f_b {
  align-items: flex-end;
}
.f_wrp {
  flex-wrap: wrap;
}
.f_rev {
  flex-direction: row-reverse;
}
.f_clm {
  flex-direction: column;
}
.f_rev.f_clm {
  flex-direction: column-reverse;
}
[class*="flx"].f_sb > ul:only-child,
[class*="flx"].f_sb > div:only-child {
  flex: 1 1 auto;
}
.full {
  width: 100%;
}
.half {
  width: 50%;
}
.f_sb > .half {
  width: calc(50% - 4.8%);
}
.third {
  width: 33.333%;
}
.f_sb > .third {
  width: calc(33.333% - (4.8% * 0.6667));
}
.two-thirds {
  width: 66.666%;
}
.f_sb > .two-thirds {
  width: calc(66.666% - (4.8% * 0.75));
}
.fourth {
  width: 25%;
}
.f_sb > .fourth {
  width: 25%;
}
.three-fourths {
  width: 75%;
}
.f_sb > .three-fourths {
  width: calc(75% - (4.8% * 1.6667));
}
.fifth {
  width: 20%;
}
.two-fifths {
  width: 40%;
}
.f_sb > .two-fifths {
  width: calc(40% - (4.8% * 0.6667));
}
.three-fifths {
  width: 60%;
}
.f_sb > .three-fifths {
  width: calc(60% - (4.8% * 1.333));
}
.four-fifths {
  width: 80%;
}
.f_sb > .four-fifths {
  width: calc(80% - (4.8% * 1.6667));
}
.sixth {
  width: 16.666%;
}
.f_sb > .sixth {
  width: 14.285%;
}
.seventh {
  width: 14.285%;
}
@media screen and (max-width: 1600px) {
  [class*="flx"][class*="-ato-rsp"] > .fourth {
    width: 40%;
  }
  .f_sb[class*="-ato-rsp"] > .fourth {
    width: calc(40% - (4.8% * 0.6667));
  }
  .f_sb[class*="-ato-rsp"] > .three-fourths {
    width: 60%;
  }
  .f_sb[class*="-ato-rsp"] > .three-fourths {
    width: calc(60% - (4.8% * 1.333));
  }
  [class*="flx"][class*="-blk-1600"] {
    display: block;
  }
  [class*="flx"][class*="-blk-1600"] > ul:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1600"] > li:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1600"] > header:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1600"] > div:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1600"] > picture:not([class*="-grd"]) {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1440px) {
  [class*="flx"][class*="-blk-1440"] {
    display: block;
  }
  [class*="flx"][class*="-blk-1440"] > ul:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1440"] > li:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1440"] > header:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1440"] > div:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1440"] > picture:not([class*="-grd"]) {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1280px) {
  [class*="flx"][class*="-ato-rsp"] > .third {
    width: 40%;
  }
  .f_sb[class*="-ato-rsp"] > .third {
    width: calc(40% - (4.8% * 0.6667));
  }
  .f_sb[class*="-ato-rsp"] > .two-thirds {
    width: 60%;
  }
  .f_sb[class*="-ato-rsp"] > .two-thirds {
    width: calc(60% - (4.8% * 1.333));
  }
  [class*="flx"][class*="-ato-rsp"] > .two-fifths,
  [class*="flx"][class*="-ato-rsp"] > .three-fifths {
    width: 50%;
  }
  .f_sb[class*="-ato-rsp"] > .two-fifths,
  .f_sb[class*="-ato-rsp"] > .three-fifths {
    width: calc(50% - (4.8% * 0.6667));
  }
  [class*="flx"][class*="-blk-1280"] {
    display: block;
  }
  [class*="flx"][class*="-blk-1280"] > ul:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1280"] > li:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1280"] > header:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1280"] > div:not([class*="-grd"]),
  [class*="flx"][class*="-blk-1280"] > picture:not([class*="-grd"]) {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 1024px) {
  [class*="flx"][class*="-ato-rsp"],
  [class*="flx"][class*="-blk-1024"] {
    display: block;
  }
  [class*="flx"][class*="-ato-rsp"] > ul,
  [class*="flx"][class*="-ato-rsp"] > li,
  [class*="flx"][class*="-ato-rsp"] > header,
  [class*="flx"][class*="-ato-rsp"] > div,
  [class*="flx"][class*="-ato-rsp"] > picture:not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-1024"]
    > ul:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-1024"]
    > li:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-1024"]
    > header:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-1024"]
    > div:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-1024"]
    > picture:not([class*="-grd"]):not([class*="rsp_img"]) {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }
  .f_sb[class*="-ato-rsp"] > ul,
  .f_sb[class*="-ato-rsp"] > header,
  .f_sb[class*="-ato-rsp"] > div,
  .f_sb[class*="-ato-rsp"] > picture,
  .f_sb[class*="flx"][class*="-ato-rsp"] > ul,
  .f_sb[class*="flx"][class*="-ato-rsp"] > header,
  .f_sb[class*="flx"][class*="-ato-rsp"] > div,
  .f_sb[class*="flx"][class*="-ato-rsp"] > picture,
  .f_is-olp[class*="flx"][class*="-ato-rsp"] > ul,
  .f_is-olp[class*="flx"][class*="-ato-rsp"] > header,
  .f_is-olp[class*="flx"][class*="-ato-rsp"] > div,
  .f_is-olp[class*="flx"][class*="-ato-rsp"] > picture {
    width: 100% !important;
  }
}
@media screen and (max-width: 800px) {
  [class*="flx"][class*="-blk-800"] {
    display: block;
  }
  [class*="flx"][class*="-blk-800"]
    > ul:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-800"]
    > li:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-800"]
    > header:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-800"]
    > div:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-800"]
    > picture:not([class*="-grd"]):not([class*="rsp_img"]) {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  [class*="flx"][class*="-blk-500"] {
    display: block;
  }
  [class*="flx"][class*="-blk-500"]
    > ul:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-500"]
    > li:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-500"]
    > header:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-500"]
    > div:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-500"]
    > picture:not([class*="-grd"]):not([class*="rsp_img"]) {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 380px) {
  [class*="flx"][class*="-blk-380"] {
    display: block;
  }
  [class*="flx"][class*="-blk-380"]
    > ul:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-380"]
    > li:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-380"]
    > header:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-380"]
    > div:not([class*="-grd"]):not([class*="rsp_img"]),
  [class*="flx"][class*="-blk-380"]
    > picture:not([class*="-grd"]):not([class*="rsp_img"]) {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
:root {
}
body {
  font-family: "Georama", sans-serif;
  line-height: 2.23;
  font-size: 1rem;
  letter-spacing: 0.05em;
}
.cnt-stl {
  font-size: 1rem;
  letter-spacing: 0.05em;
}
.fnt_pl {
  font-size: 1.14rem;
  line-height: 1.66;
  letter-spacing: 0.1em;
}
time {
  font-weight: 300;
  letter-spacing: 0.1em;
}
small i {
  font-weight: 300;
}
[class*="fnt_t-"] {
  display: block;
  font-style: normal;
}
section header em {
  font-style: normal;
}
section header > strong,
section header > a > strong,
section header > b,
section header > a > b {
  display: block;
}
.fnt_t-big {
  font-family: "Georama", sans-serif;
  font-size: 5rem;
  letter-spacing: 0.075em;
  line-height: 1.21;
  font-weight: 400;
  text-transform: none;
  word-break: break-word;
}
.fnt_t-co {
  font-family: "Georama", sans-serif;
  font-size: 3.056rem;
  letter-spacing: 0em;
  line-height: 1;
  font-weight: 400;
  text-transform: none;
  word-break: break-word;
}
.fnt_t-co > b,
.fnt_t-co > strong {
  font-family: "Georama", sans-serif;
  font-size: 0.873em;
  letter-spacing: 0em;
  line-height: 1;
  font-weight: 400;
  text-transform: none;
}
h1,
.fnt_t-1,
section header > :first-child:not(a):not(.fnt_t-big) {
  font-family: "Georama", sans-serif;
  font-size: 2.84rem;
  letter-spacing: 0.069em;
  line-height: 1.2;
  font-weight: 400;
  text-transform: none;
  word-break: break-word;
}
.fnt_t-2 {
  font-family: "Georama", sans-serif;
  font-size: 1.7rem;
  letter-spacing: 0.12em;
  line-height: 1.4;
  font-weight: 400;
  text-transform: none;
}
h2,
h3,
.fnt_t-3,
section header > *:nth-child(2):not(p),
aside header > *:first-child:not(a) {
  font-family: "Georama", sans-serif;
  font-size: 1.33rem;
  letter-spacing: 0.2em;
  line-height: 1.42;
  font-weight: 400;
  text-transform: none;
}
.fnt_t-4,
.cnt-stl h4 {
  font-family: "Georama", sans-serif;
  font-size: 1.14rem;
  letter-spacing: 0.1em;
  line-height: 1.55;
  font-weight: 500;
  text-transform: none;
}
.fnt_t-5,
.cnt-stl h5 {
  font-family: "Georama", sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  line-height: 1.69;
  font-weight: 500;
  text-transform: none;
}
.fnt_t-6,
.cnt-stl h6 {
  font-family: "Georama", sans-serif;
  font-size: 0.76rem;
  letter-spacing: 0.15em;
  line-height: 2.18;
  font-weight: 500;
  text-transform: none;
}
.fnt_qte {
  font-family: "Georama", sans-serif;
  font-size: 1.14rem;
  letter-spacing: 0.1em;
  line-height: 1.41;
  font-weight: 400;
  text-transform: none;
  font-style: normal;
}
.fnt_phn {
  font-family: "Georama", sans-serif;
  font-size: 0.95rem;
  letter-spacing: 0.15em;
  line-height: 1.2;
  font-weight: 700;
}
.btn-stl {
  font-family: "Georama", sans-serif;
  font-size: 0.85rem;
  letter-spacing: 0.15em;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
}
.btn.v1 {
  font-family: "Georama", sans-serif;
  font-size: 0.85rem;
  letter-spacing: 0.15em;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
}
.btn.v2 {
  font-family: "Georama", sans-serif;
  font-size: 15px;
  letter-spacing: 0.15em;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
}
fieldset li label:not([class*="fnt_"]) {
  font-family: "Georama", sans-serif;
  letter-spacing: 0em;
  line-height: 1.2;
  font-weight: 500;
  text-transform: none;
}
@media screen and (max-width: 1600px) {
  :root {
  }
  .fnt_t-big {
    font-size: calc(5rem * 0.85);
  }
  h1,
  .fnt_t-1,
  section header > :first-child:not(a):not(.fnt_t-big) {
    font-size: calc(2.84rem * 0.95);
  }
}
@media screen and (max-width: 1280px) {
  :root {
  }
  .fnt_t-big {
    font-size: calc(5rem * 0.75);
  }
  .fnt_t-co {
    font-size: calc(3.056rem * 0.9);
  }
  h1,
  .fnt_t-1,
  section header > :first-child:not(a):not(.fnt_t-big) {
    font-size: calc(2.84rem * 0.9);
  }
  h2,
  h3,
  .fnt_t-3,
  section header > *:nth-child(2):not(p),
  aside header > *:first-child:not(a) {
    font-size: calc(1.33rem * 0.85);
  }
  .fnt_phn {
    font-size: calc(0.95rem * 1);
  }
}
@media screen and (max-width: 800px) {
  :root {
  }
  .fnt_t-big {
    font-size: calc(5rem * 0.65);
  }
  .fnt_t-co {
    font-size: calc(3.056rem * 0.8);
  }
  h1,
  .fnt_t-1,
  section header > :first-child:not(a):not(.fnt_t-big) {
    font-size: calc(2.84rem * 0.8);
  }
  .fnt_t-2 {
    font-size: calc(1.7rem * 0.9);
  }
  .fnt_qte {
    font-size: calc(1.14rem * 0.95);
  }
  .fnt_phn {
    font-size: calc(0.95rem * 0.95);
  }
}
@media screen and (max-width: 500px) {
  :root {
  }
  .fnt_t-big {
    font-size: calc(5rem * 0.6);
  }
  .fnt_t-co {
    font-size: calc(3.056rem * 0.58);
  }
  h1,
  .fnt_t-1,
  section header > :first-child:not(a):not(.fnt_t-big) {
    font-size: calc(2.84rem * 0.7);
  }
  .fnt_t-2 {
    font-size: calc(1.7rem * 0.8);
  }
  h2,
  h3,
  .fnt_t-3,
  section header > *:nth-child(2):not(p),
  aside header > *:first-child:not(a) {
    font-size: calc(1.33rem * 0.7);
  }
  .fnt_t-4,
  .cnt-stl h4 {
    font-size: calc(1.14rem * 0.9);
  }
  .fnt_qte {
    font-size: calc(1.14rem * 0.9);
  }
  .fnt_phn {
    font-size: calc(0.95rem * 0.95);
  }
  .fnt_pl {
    font-size: calc(1.14rem * 0.85);
  }
}
@media screen and (max-width: 380px) {
  :root {
  }
  .fnt_t-big {
    font-size: calc(5rem * 0.5);
  }
}
:root {
}
:root {
}
.lt-bg {
  background-color: #ffffff;
  color: #191919;
}
.lt-bg .bg-bx.lk-bg,
.dk-bg .bg-bx.ulk-bg {
  color: #ffffff;
}
.dk-bg .bg-bx.ulk-bg.alt-bg {
  color: #191919;
}
.lt-bg .bg-bx.lk-bg::before,
.lt-bg .bg-bx.lk-bg .bg-bx.lk-bg::before,
.dk-bg .bg-bx.ulk-bg::before {
  background-color: #191919;
}
.lt-bg .bg-bx.lk-bg.alt-bg::before,
.dk-bg .bg-bx.ulk-bg.alt-bg::before {
  background-color: #ffffff;
}
.sd-zn .lt-bg,
.cnt-zn .lt-bg {
  background-color: #ffffff;
}
.dk-bg {
  background-color: #292929;
  color: #ffffff;
}
.dk-bg.alt-bg {
  background-color: #191919;
}
.dk-bg .bg-bx.lk-bg,
.lt-bg .bg-bx.ulk-bg {
  color: #ffffff;
}
.dk-bg .bg-bx.lk-bg::before,
.lt-bg .bg-bx.ulk-bg::before {
  background-color: #191919;
}
.dk-bg .bg-bx.lk-bg.alt-bg::before,
.lt-bg .bg-bx.ulk-bg.alt-bg::before {
  background-color: #292929;
}
.lt-bg.transparent-bg,
.dk-bg.transparent-bg {
  background-color: transparent;
  box-shadow: none;
}
.lt-bg.alt-bg {
  background-color: #ffffff;
}
.dk-bg.alt-bg .bg-bx.lk-bg::before {
  background-color: #292929;
}
.lt-bg.alt-bg .bg-bx.lk-bg::before {
  background-color: #191919;
}
.clr-btn {
  color: #a0a0a0;
}
.lt-bg .clr-pry,
.dk-bg .bg-bx.ulk-bg .clr-pry {
  color: #a1a4a7;
}
.lt-bg .clr-sec,
.dk-bg .bg-bx.ulk-bg .clr-sec {
  color: #000000;
}
.lt-bg .clr-acc,
.dk-bg .bg-bx.ulk-bg .clr-acc {
  color: #a0a0a0;
}
.lt-bg .clr-lnk {
  color: #a0a0a0;
}
.dk-bg .bg-bx.ulk-bg .clr-lnk {
  color: #191919;
}
.lt-bg .clr-txt,
.dk-bg .bg-bx.ulk-bg .clr-txt {
  color: #191919;
}
.dk-bg .clr-pry,
.lt-bg .bg-bx.ulk-bg .clr-pry {
  color: #ffffff;
}
.dk-bg .clr-sec,
.lt-bg .bg-bx.ulk-bg .clr-sec {
  color: #ffffff;
}
.dk-bg .clr-acc,
.lt-bg .bg-bx.ulk-bg .clr-acc {
  color: #d6dbdf;
}
.dk-bg .clr-lnk,
.lt-bg .bg-bx.ulk-bg .clr-lnk {
  color: #a0a0a0;
}
.dk-bg .clr-txt,
.lt-bg .bg-bx.ulk-bg .clr-txt {
  color: #ffffff;
}
[class*="fill-"] {
  stroke: transparent !important;
}
.fill-button {
  fill: #a0a0a0;
}
.lt-bg [class*="-white"],
.dk-bg .bg-bx.ulk-bg [class*="-white"] {
  fill: #ffffff;
}
.lt-bg [class*="-light"],
.dk-bg .bg-bx.ulk-bg [class*="-light"] {
  fill: #191919;
}
.lt-bg [class*="-dark"],
.dk-bg .bg-bx.ulk-bg [class*="-dark"] {
  fill: #292929;
}
.lt-bg [class*="-primary"],
.dk-bg .bg-bx.ulk-bg [class*="-primary"] {
  fill: #a1a4a7;
}
.lt-bg [class*="-secondary"],
.dk-bg .bg-bx.ulk-bg [class*="-secondary"] {
  fill: #000000;
}
.lt-bg [class*="-accent"],
.dk-bg .bg-bx.ulk-bg [class*="-accent"] {
  fill: #a0a0a0;
}
.lt-bg [class*="-border"],
.dk-bg .bg-bx.ulk-bg [class*="-border"] {
  fill: rgba(0, 0, 0, 0.1);
}
.dk-bg [class*="-white"],
.lt-bg .bg-bx.ulk-bg [class*="-white"] {
  fill: #ffffff;
}
.dk-bg [class*="-light"],
.lt-bg .bg-bx.ulk-bg [class*="-light"] {
  fill: #292929;
}
.dk-bg [class*="-dark"],
.lt-bg .bg-bx.ulk-bg [class*="-dark"] {
  fill: #191919;
}
.dk-bg [class*="-primary"],
.lt-bg .bg-bx.ulk-bg [class*="-primary"] {
  fill: #ffffff;
}
.dk-bg [class*="-secondary"],
.lt-bg .bg-bx.ulk-bg [class*="-secondary"] {
  fill: #ffffff;
}
.dk-bg [class*="-accent"],
.lt-bg .bg-bx.ulk-bg [class*="-accent"] {
  fill: #d6dbdf;
}
.dk-bg [class*="-border"],
.lt-bg .bg-bx.ulk-bg [class*="-border"] {
  fill: rgba(255, 255, 255, 0.4);
}
.lt-bg a {
  color: #a0a0a0;
}
.lt-bg .bg-bx.lk-bg a,
.dk-bg .bg-bx.ulk-bg a {
  color: #ffffff;
}
.lt-bg a.clr-swp,
.dk-bg .bg-bx.ulk-bg a.clr-swp {
  color: #191919;
}
.lt-bg a.clr-swp.selected,
.dk-bg .bg-bx.ulk-bg a.clr-swp.selected,
.lt-bg .selected a.clr-swp,
.dk-bg .bg-bx.ulk-bg .selected a.clr-swp {
  color: #a0a0a0;
}
.lt-bg .btn-clr,
.dk-bg .bg-bx.ulk-bg .btn-clr {
  background-color: #a0a0a0;
  color: #ffffff;
}
.lt-bg li.active > a.clr-swp,
.dk-bg .bg-bx.ulk-bg li.active > a.clr-swp {
  color: #a0a0a0;
}
.lt-bg .btn-clr.active:not(.no-hvr),
.lt-bg .btn-clr.active:not(.no-hvr),
.dk-bg .bg-bx.ulk-bg .btn-clr.active:not(.no-hvr),
.dk-bg .bg-bx.ulk-bg .btn-clr.active:not(.no-hvr) {
  background-color: #292929;
  color: #ffffff;
}
.dk-bg a,
.lt-bg .bg-bx.ulk-bg a,
.dk-bg .bg-bx.ulk-bg.alt-bg a,
.lt-bg .bg-bx.lk-bg.alt-bg a {
  color: #a0a0a0;
}
.dk-bg a.clr-swp,
.lt-bg .bg-bx.ulk-bg a.clr-swp {
  color: #ffffff;
}
.dk-bg a.clr-swp.selected,
.lt-bg .bg-bx.ulk-bg a.clr-swp.selected,
.dk-bg .selected a.clr-swp,
.lt-bg .bg-bx.ulk-bg .selected a.clr-swp {
  color: #a0a0a0;
}
.dk-bg .btn-clr,
.lt-bg .bg-bx.ulk-bg .btn-clr {
  background-color: #a0a0a0;
  color: #ffffff;
}
.dk-bg li.active > a.clr-swp,
.lt-bg .bg-bx.ulk-bg li.active > a.clr-swp {
  color: #a0a0a0;
}
.dk-bg .btn-clr.active:not(.no-hvr),
.dk-bg .btn-clr.active:not(.no-hvr),
.lt-bg .bg-bx.ulk-bg .btn-clr.active:not(.no-hvr),
.lt-bg .bg-bx.ulk-bg .btn-clr.active:not(.no-hvr) {
  background-color: #191919;
  color: #191919;
}
.active.bg-bx.btn-clr-hvr:not(.no-hvr),
a.active .bg-bx.btn-clr-hvr:not(.no-hvr),
.active.bg-bx.btn-clr-hvr:not(.no-hvr)::before,
a.active .bg-bx.btn-clr-hvr:not(.no-hvr)::before {
  background-color: #a0a0a0 !important;
  color: #ffffff !important;
}
.lt-bg .ui-scroll::-webkit-scrollbar-thumb,
.dk-bg .bg-bx.ulk-bg .ui-scroll::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
}
.lt-bg .ui-scroll::-webkit-scrollbar-track,
.dk-bg .bg-bx.ulk-bg .ui-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}
.dk-bg .ui-scroll::-webkit-scrollbar-thumb,
.lt-bg .bg-bx.ulk-bg .ui-scroll::-webkit-scrollbar-thumb {
  background-color: #d6dbdf;
}
.dk-bg .ui-scroll::-webkit-scrollbar-track,
.lt-bg .bg-bx.ulk-bg .ui-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.4);
}
.lt-bg [class*="bdr_"],
.dk-bg .bg-bx.ulk-bg [class*="bdr_"] {
  border-color: rgba(0, 0, 0, 0.1);
}
.dk-bg [class*="bdr_"],
.lt-bg .bg-bx.ulk-bg [class*="bdr_"] {
  border-color: rgba(255, 255, 255, 0.4);
}
.lt-bg .fnt_tc-big,
.dk-bg .bg-bx.ulk-bg .fnt_tc-big {
  color: #191919;
}
.lt-bg .fnt_tc-co,
.dk-bg .bg-bx.ulk-bg .fnt_tc-co {
  color: #191919;
}
.lt-bg h1 > em,
.lt-bg .fnt_tc-k,
.dk-bg .bg-bx.ulk-bg h1 > em,
.dk-bg .bg-bx.ulk-bg .fnt_tc-k {
  color: #191919;
}
.lt-bg .fnt_itm-k,
.dk-bg .bg-bx.ulk-bg .fnt_itm-k {
  color: #191919;
}
.lt-bg h1,
.lt-bg .fnt_tc-1,
section.lt-bg header > *:first-child:not(a),
section.dk-bg .bg-bx.ulk-bg header > *:first-child:not(a) {
  color: #191919;
}
.lt-bg .fnt_tc-2,
.lt-bg .cnt-stl h2,
.lt-bg .cnt-stl h3,
section.lt-bg header > *:nth-child(2):not(p),
section.dk-bg .bg-bx.ulk-bg header > *:nth-child(2):not(p) {
  color: #191919;
}
.lt-bg .fnt_tc-3 {
  color: #191919;
}
.lt-bg .cnt-stl h4,
.lt-bg .fnt_tc-4,
.dk-bg .bg-bx.ulk-bg.alt-bg .fnt_tc-4 {
  color: #191919;
}
.lt-bg .cnt-stl h5,
.lt-bg .fnt_tc-5 {
  color: #a1a4a7;
}
.lt-bg .cnt-stl h6,
.lt-bg .fnt_tc-6 {
  color: #191919;
}
h1 u,
h2 u,
h3 u,
h4 u,
h5 u,
h6 u,
section header u,
[class*="fnt_t"]:first-child u,
.cnt-stl h1 strong {
  color: #a0a0a0;
}
.lt-bg .bg-bx.lk-bg h1 u,
.lt-bg .bg-bx.lk-bg h2 u,
.lt-bg .bg-bx.lk-bg h3 u,
.lt-bg .bg-bx.lk-bg h4 u,
.lt-bg .bg-bx.lk-bg h5 u,
.lt-bg .bg-bx.lk-bg h6 u,
section.lt-bg .bg-bx.lk-bg header u,
.lt-bg .bg-bx.lk-bg [class*="fnt_t"]:first-child u,
.lt-bg .bg-bx.lk-bg .cnt-stl h1 strong,
.dk-bg .bg-bx.ulk-bg h1 u,
.dk-bg .bg-bx.ulk-bg h2 u,
.dk-bg .bg-bx.ulk-bg h3 u,
.dk-bg .bg-bx.ulk-bg h4 u,
.dk-bg .bg-bx.ulk-bg h5 u,
.dk-bg .bg-bx.ulk-bg h6 u,
section.dk-bg .bg-bx.ulk-bg header u,
.dk-bg .bg-bx.ulk-bg [class*="fnt_t"]:first-child u,
.dk-bg .bg-bx.ulk-bg .cnt-stl h1 strong {
  color: #292929;
}
section.lt-bg header > *:nth-child(2) u,
section.dk-bg .bg-bx.ulk-bg header > *:nth-child(2) u,
.lt-bg [class*="fnt_t"] + [class*="fnt_t"] u {
  color: #191919;
}
section.dk-bg header > *:nth-child(2) u,
section.lt-bg .bg-bx.ulk-bg header > *:nth-child(2) u,
.dk-bg [class*="fnt_t"] + [class*="fnt_t"] u {
  color: #ffffff;
}
.dk-bg .fnt_tc-big,
.lt-bg .bg-bx.ulk-bg .fnt_tc-big {
  color: #ffffff;
}
.dk-bg .fnt_tc-co,
.lt-bg .bg-bx.ulk-bg .fnt_tc-co {
  color: #ffffff;
}
.dk-bg h1 > em,
.dk-bg .fnt_tc-k,
.lt-bg .bg-bx.ulk-bg h1 > em,
.lt-bg .bg-bx.ulk-bg .fnt_tc-k {
  color: #ffffff;
}
.dk-bg .fnt_itm-k,
.lt-bg .bg-bx.ulk-bg .fnt_itm-k {
  color: #ffffff;
}
.dk-bg h1,
.dk-bg .fnt_tc-1,
.lt-bg .bg-bx.ulk-bg h1,
.lt-bg .bg-bx.ulk-bg .fnt_tc-1,
.lt-bg .bg-bx.lk-bg h1,
.lt-bg .bg-bx.lk-bg .fnt_tc-1,
section.dk-bg header > *:first-child:not(a),
section.lt-bg .bg-bx.ulk-bg header > *:first-child:not(a),
section.dk-bg .bg-bx.ulk-bg header > *:first-child:not(a) {
  color: #ffffff;
}
.dk-bg .fnt_tc-2,
.dk-bg .cnt-stl h2,
.dk-bg .cnt-stl h3,
.lt-bg .bg-bx.ulk-bg .fnt_tc-2,
.lt-bg .bg-bx.ulk-bg h2,
.lt-bg .bg-bx.ulk-bg h3,
.lt-bg .bg-bx.lk-bg .fnt_tc-2,
.lt-bg .bg-bx.lk-bg h2,
.lt-bg .bg-bx.lk-bg h3,
section.dk-bg header > *:nth-child(2):not(p),
section.lt-bg .bg-bx.ulk-bg header > *:nth-child(2):not(p) {
  color: #d6dbdf;
}
.dk-bg .fnt_tc-3,
.lt-bg .bg-bx.ulk-bg .fnt_tc-3,
.lt-bg .bg-bx.lk-bg .fnt_tc-3 {
  color: #ffffff;
}
.dk-bg .cnt-stl h4,
.dk-bg .fnt_tc-4,
.lt-bg .bg-bx.ulk-bg h4,
.lt-bg .bg-bx.ulk-bg .fnt_tc-4,
.lt-bg .bg-bx.lk-bg h4,
.lt-bg .bg-bx.lk-bg .fnt_tc-4,
.lt-bg .bg-bx.lk-bg h5,
.lt-bg .bg-bx.lk-bg .fnt_tc-5 {
  color: #ffffff;
}
.dk-bg .cnt-stl h5,
.dk-bg .fnt_tc-5,
.lt-bg .bg-bx.ulk-bg h5,
.lt-bg .bg-bx.ulk-bg .fnt_tc-5 {
  color: #a0a0a0;
}
.dk-bg .cnt-stl h6,
.dk-bg .fnt_tc-6,
.lt-bg .bg-bx.ulk-bg h6,
.lt-bg .bg-bx.lk-bg h6,
.lt-bg .bg-bx.ulk-bg .fnt_tc-6 {
  color: #ffffff;
}
section.dk-bg header > *:nth-child(2) u {
  color: #ffffff;
}
.lt-bg label,
.dk-bg .bg-bx.ulk-bg label {
  color: #191919;
}
.lt-bg .inp-sgt,
.lt-bg .input-text,
.dk-bg .bg-bx.ulk-bg .inp-sgt,
.dk-bg .bg-bx.ulk-bg .input-text {
  border-color: rgba(0, 0, 0, 0.1);
  color: #191919;
  background: #ffffff;
}
.lt-bg li.focused .inp-sgt,
.lt-bg li.focused .input-text,
.dk-bg .bg-bx.ulk-bg li.focused .inp-sgt,
.dk-bg .bg-bx.ulk-bg li.focused .input-text {
  border-color: #0066ee;
}
.lt-bg li.focused .inp-sgt label,
.lt-bg li.focused .input-text label,
.dk-bg .bg-bx.ulk-bg li.focused .inp-sgt label,
.dk-bg .bg-bx.ulk-bg li.focused .input-text label {
  color: #0066ee;
}
.lt-bg .invalid .inp-sgt,
.lt-bg .invalid .input-text,
.dk-bg .bg-bx.ulk-bg .invalid .inp-sgt,
.dk-bg .bg-bx.ulk-bg .invalid .input-text {
  border-color: #f34039;
}
.lt-bg .valid .inp-sgt,
.lt-bg .valid .input-text,
.dk-bg .bg-bx.ulk-bg .valid .inp-sgt,
.dk-bg .bg-bx.ulk-bg .valid .input-text {
  border-color: #8ac74c;
}
.lt-bg .inp-sgt .sgts {
  background-color: #ffffff;
  border-color: rgba(0, 0, 0, 0.1);
}
.lt-bg .inp-mrk label.replace {
  border-color: rgba(0, 0, 0, 0.1);
}
.lt-bg .inp-mrk input:checked + label.replace {
  border-color: #a0a0a0;
}
.lt-bg .inp-mrk label.replace.disabled,
.lt-bg .inp-mrk input:disabled + label.replace {
  background-color: rgba(0, 0, 0, 0.1);
}
.lt-bg .inp-mrk label.replace.disabled::before,
.lt-bg .inp-mrk input:disabled + label.replace::before {
  background-color: rgba(0, 0, 0, 0.1);
}
.lt-bg .inp-mrk label.replace::before {
  background-color: rgba(0, 0, 0, 0.1);
}
.lt-bg .inp-mrk input:checked + label.replace::before {
  background-color: #a0a0a0;
}
.lt-bg .inp-mrk label.replace + label,
.lt-bg .inp-mrk label.toggle + label {
  color: #191919;
}
.lt-bg .inp-mrk label.replace.disabled + label,
.lt-bg .inp-mrk input:disabled + label.replace + label {
  color: rgba(0, 0, 0, 0.1);
}
.lt-bg .inp-sgt .tags > li {
  background-color: #0066ee;
  color: #ffffff;
}
.lt-bg .inp-sgt .tags > li .remove::before,
.lt-bg .inp-sgt .tags > li .remove::after,
.lt-bg .inp-sgt .tags > li [data-role="remove"]:before,
.lt-bg .inp-sgt .tags > li [data-role="remove"]:after {
  background-color: #ffffff;
}
.lt-bg input:checked + .btn-clr-hvr:not(.no-hvr),
.dk-bg .bg-bx.ulk-bg input:checked + .btn-clr-hvr:not(.no-hvr) {
  background-color: #a0a0a0 !important;
  color: #ffffff !important;
}
.lt-bg input:checked + .btn-clr-hvr:not(.no-hvr) > *,
.dk-bg .bg-bx.ulk-bg input:checked + .btn-clr-hvr:not(.no-hvr) > * {
  color: #ffffff !important;
}
.dk-bg label,
.lt-bg .bg-bx.ulk-bg label {
  color: #191919;
}
.dk-bg .inp-sgt,
.dk-bg .input-text,
.lt-bg .bg-bx.ulk-bg .inp-sgt,
.lt-bg .bg-bx.ulk-bg .input-text {
  border-color: rgba(255, 255, 255, 0.4);
  color: #191919;
  background: #ffffff;
}
.dk-bg li.focused .inp-sgt,
.dk-bg li.focused .input-text,
.lt-bg .bg-bx.ulk-bg li.focused .inp-sgt,
.lt-bg .bg-bx.ulk-bg li.focused .input-text {
  border-color: #0066ee;
}
.dk-bg li.focused .inp-sgt label,
.dk-bg li.focused .input-text label,
.lt-bg .bg-bx.ulk-bg li.focused .inp-sgt label,
.lt-bg .bg-bx.ulk-bg li.focused .input-text label {
  color: #0066ee;
}
.dk-bg .invalid .inp-sgt,
.dk-bg .invalid .input-text,
.lt-bg .bg-bx.ulk-bg .invalid .inp-sgt,
.lt-bg .bg-bx.ulk-bg .invalid .input-text {
  border-color: #f34039;
}
.dk-bg .valid .inp-sgt,
.dk-bg .valid .input-text,
.lt-bg .bg-bx.ulk-bg .valid .inp-sgt,
.lt-bg .bg-bx.ulk-bg .valid .input-text {
  border-color: #8ac74c;
}
.dk-bg .inp-sgt .sgts {
  background-color: #292929;
  border-color: rgba(255, 255, 255, 0.4);
}
.dk-bg .inp-mrk label.replace {
  border-color: rgba(255, 255, 255, 0.4);
}
.dk-bg .inp-mrk input:checked + label.replace {
  border-color: #a0a0a0;
}
.dk-bg .inp-mrk label.replace.disabled,
.dk-bg .inp-mrk input:disabled + label.replace {
  background-color: rgba(255, 255, 255, 0.4);
}
.dk-bg .inp-mrk label.replace.disabled::before,
.dk-bg .inp-mrk input:disabled + label.replace::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.dk-bg .inp-mrk label.replace::before {
  background-color: rgba(255, 255, 255, 0.4);
}
.dk-bg .inp-mrk input:checked + label.replace::before {
  background-color: #a0a0a0;
}
.dk-bg .inp-mrk label.replace + label,
.dk-bg .inp-mrk label.toggle + label {
  color: #ffffff;
}
.dk-bg .inp-mrk label.replace.disabled + label,
.dk-bg .inp-mrk input:disabled + label.replace + label {
  color: rgba(255, 255, 255, 0.4);
}
.dk-bg .inp-sgt .tags > li {
  background-color: #0066ee;
  color: #ffffff;
}
.dk-bg .inp-sgt .tags > li .remove::before,
.dk-bg .inp-sgt .tags > li .remove::after,
.dk-bg .inp-sgt .tags > li [data-role="remove"]:before,
.dk-bg .inp-sgt .tags > li [data-role="remove"]:after {
  background-color: #ffffff;
}
.dk-bg input:checked + .btn-clr-hvr:not(.no-hvr),
.lt-bg .bg-bx.ulk-bg input:checked + .btn-clr-hvr:not(.no-hvr) {
  background-color: #a0a0a0 !important;
  color: #ffffff !important;
}
.dk-bg input:checked + .btn-clr-hvr:not(.no-hvr) > *,
.lt-bg .bg-bx.ulk-bg input:checked + .btn-clr-hvr:not(.no-hvr) > * {
  color: #ffffff !important;
}
.lt-bg [data-role="arrows"] button,
.dk-bg .bg-bx.ulk-bg [data-role="arrows"] button {
  color: #a0a0a0;
}
.dk-bg [data-role="arrows"] button,
.lt-bg .bg-bx.ulk-bg [data-role="arrows"] button {
  color: #ffffff;
}
@media screen and (min-width: 1025px) {
  .lt-bg.splt-bg-l {
    background: linear-gradient(
      to right,
      #ffffff calc(50% + calc(4.8% * 2)),
      #292929 calc(50% - calc(4.8% * 2))
    );
  }
  .lt-bg.splt-bg-r {
    background: linear-gradient(
      to left,
      #ffffff calc(50% + calc(4.8% * 2)),
      #292929 calc(50% - calc(4.8% * 2))
    );
  }
  .lt-bg a:hover,
  .lt-bg a:focus,
  .lt-bg .bg-bx.lk-bg a:hover,
  .lt-bg .bg-bx.lk-bg a:focus,
  .lt-bg .bg-bx.lk-bg.alt-bg a:hover,
  .lt-bg .bg-bx.lk-bg.alt-bg a:focus,
  .dk-bg .bg-bx.ulk-bg a:hover,
  .dk-bg .bg-bx.ulk-bg a:focus,
  .dk-bg .bg-bx.ulk-bg.alt-bg a:hover,
  .dk-bg .bg-bx.ulk-bg.alt-bg a:focus {
    color: #191919;
  }
  .dk-bg .bg-bx.ulk-bg.alt-bg a.clr-swp:hover,
  .dk-bg .bg-bx.ulk-bg.alt-bg a.clr-swp:focus {
    color: #191919;
  }
  .dk-bg .bg-bx.ulk-bg .bg-bx.lk-bg a.clr-swp:hover,
  .dk-bg .bg-bx.ulk-bg .bg-bx.lk-bg a.clr-swp:focus {
    color: #ffffff;
  }
  .lt-bg a.clr-swp:hover,
  .lt-bg a.clr-swp:focus,
  .dk-bg .bg-bx.ulk-bg a.clr-swp:hover,
  .dk-bg .bg-bx.ulk-bg a.clr-swp:focus {
    color: #a0a0a0;
  }
  .lt-bg .btn-clr:not(.no-hvr):hover,
  .lt-bg .btn-clr:not(.no-hvr):focus,
  .lt-bg a:hover .btn-clr:not(.no-hvr),
  .lt-bg a:focus .btn-clr:not(.no-hvr),
  .dk-bg .bg-bx.ulk-bg .btn-clr:not(.no-hvr):hover,
  .dk-bg .bg-bx.ulk-bg .btn-clr:not(.no-hvr):focus,
  .dk-bg .bg-bx.ulk-bg a:hover .btn-clr:not(.no-hvr),
  .dk-bg .bg-bx.ulk-bg a:focus .btn-clr:not(.no-hvr) {
    background-color: #292929;
    color: #ffffff;
  }
  .lt-bg .btn-clr-hvr:not(.no-hvr):hover,
  .lt-bg .btn-clr-hvr:not(.no-hvr):focus,
  .lt-bg a:hover .btn-clr-hvr:not(.no-hvr),
  .lt-bg a:focus .btn-clr-hvr:not(.no-hvr),
  .dk-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):hover,
  .dk-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):focus,
  .dk-bg .bg-bx.ulk-bg a:hover .btn-clr-hvr:not(.no-hvr),
  .dk-bg .bg-bx.ulk-bg a:focus .btn-clr-hvr:not(.no-hvr) {
    background-color: #a0a0a0 !important;
    color: #ffffff !important;
  }
  .lt-bg .btn-clr-hvr:not(.no-hvr):hover > *,
  .lt-bg .btn-clr-hvr:not(.no-hvr):focus > *,
  .lt-bg a:hover .btn-clr-hvr:not(.no-hvr) > *,
  .lt-bg a:focus .btn-clr-hvr:not(.no-hvr) > *,
  .dk-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):hover > *,
  .dk-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):focus > *,
  .dk-bg .bg-bx.ulk-bg a:hover .btn-clr-hvr:not(.no-hvr) > *,
  .dk-bg .bg-bx.ulk-bg a:focus .btn-clr-hvr:not(.no-hvr) > * {
    color: #ffffff !important;
  }
  .dk-bg a:hover,
  .dk-bg a:focus,
  .lt-bg .bg-bx.ulk-bg a:hover,
  .lt-bg .bg-bx.ulk-bg a:focus {
    color: #ffffff;
  }
  .dk-bg a.clr-swp:hover,
  .dk-bg a.clr-swp:focus,
  .lt-bg .bg-bx.ulk-bg a.clr-swp:hover,
  .lt-bg .bg-bx.ulk-bg a.clr-swp:focus {
    color: #a0a0a0;
  }
  .dk-bg .btn-clr:not(.no-hvr):hover,
  .dk-bg .btn-clr:not(.no-hvr):focus,
  .dk-bg a:hover .btn-clr:not(.no-hvr),
  .dk-bg a:focus .btn-clr:not(.no-hvr),
  .lt-bg .bg-bx.ulk-bg .btn-clr:not(.no-hvr):hover,
  .lt-bg .bg-bx.ulk-bg .btn-clr:not(.no-hvr):focus,
  .lt-bg .bg-bx.ulk-bg a:hover .btn-clr:not(.no-hvr),
  .lt-bg .bg-bx.ulk-bg a:focus .btn-clr:not(.no-hvr) {
    background-color: #191919;
    color: #191919;
  }
  .bg-bx.btn-clr-hvr:not(.no-hvr):hover::before,
  .bg-bx.btn-clr-hvr:not(.no-hvr):focus::before,
  a:hover .bg-bx.btn-clr-hvr:not(.no-hvr)::before,
  a:focus .bg-bx.btn-clr-hvr:not(.no-hvr)::before,
  .dk-bg .btn-clr-hvr:not(.no-hvr):hover,
  .dk-bg .btn-clr-hvr:not(.no-hvr):focus,
  .dk-bg a:hover .btn-clr-hvr:not(.no-hvr),
  .dk-bg a:focus .btn-clr-hvr:not(.no-hvr),
  .lt-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):hover,
  .lt-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):focus,
  .lt-bg .bg-bx.ulk-bg a:hover .btn-clr-hvr:not(.no-hvr),
  .lt-bg .bg-bx.ulk-bg a:focus .btn-clr-hvr:not(.no-hvr) {
    background-color: #a0a0a0 !important;
    color: #ffffff !important;
  }
  .dk-bg .btn-clr-hvr:not(.no-hvr):hover > *,
  .dk-bg .btn-clr-hvr:not(.no-hvr):focus > *,
  .dk-bg a:hover .btn-clr-hvr:not(.no-hvr) > *,
  .dk-bg a:focus .btn-clr-hvr:not(.no-hvr) > *,
  .lt-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):hover > *,
  .lt-bg .bg-bx.ulk-bg .btn-clr-hvr:not(.no-hvr):focus > *,
  .lt-bg .bg-bx.ulk-bg a:hover .btn-clr-hvr:not(.no-hvr) > *,
  .lt-bg .bg-bx.ulk-bg a:focus .btn-clr-hvr:not(.no-hvr) > * {
    color: #ffffff !important;
  }
  .lt-bg [data-role="arrows"] button:hover,
  .lt-bg [data-role="arrows"] button:focus,
  .dk-bg .bg-bx.ulk-bg [data-role="arrows"] button:hover,
  .dk-bg .bg-bx.ulk-bg [data-role="arrows"] button:focus {
    color: #191919;
  }
  .lt-bg[class*="bg-shf-"]
    .mn_wd
    > [class*="flx"]
    > div:last-child
    [data-role="arrows"]
    button,
  .lt-bg[class*="splt-bg-"]
    .mn_wd
    > [class*="flx"]
    > div:last-child
    [data-role="arrows"]
    button {
    color: #ffffff;
  }
  .dk-bg [data-role="arrows"] button:hover,
  .dk-bg [data-role="arrows"] button:focus,
  .lt-bg .bg-bx.ulk-bg [data-role="arrows"] button:hover,
  .lt-bg .bg-bx.ulk-bg [data-role="arrows"] button:focus,
  .lt-bg[class*="bg-shf-"]
    .mn_wd
    > [class*="flx"]
    > div:last-child
    [data-role="arrows"]
    button:hover,
  .lt-bg[class*="bg-shf-"]
    .mn_wd
    > [class*="flx"]
    > div:last-child
    [data-role="arrows"]
    button:focus,
  .lt-bg[class*="splt-bg-"]
    .mn_wd
    > [class*="flx"]
    > div:last-child
    [data-role="arrows"]
    button:hover,
  .lt-bg[class*="splt-bg-"]
    .mn_wd
    > [class*="flx"]
    > div:last-child
    [data-role="arrows"]
    button:focus {
    color: #a0a0a0;
  }
  .lt-bg .inp-mrk input:hover + label.replace::before {
    background-color: #a0a0a0;
  }
  .lt-bg .inp-mrk input:hover + label.replace,
  .lt-bg .inp-mrk input:focus + label.replace {
    border-color: #a0a0a0;
  }
  .lt-bg .inp-sgt .tags > li:hover,
  .lt-bg .inp-sgt .tags > li:focus {
    background-color: #292929;
    color: #ffffff;
  }
  .dk-bg .inp-mrk input:hover + label.replace::before {
    background-color: #a0a0a0;
  }
  .dk-bg .inp-mrk input:hover + label.replace,
  .dk-bg .inp-mrk input:focus + label.replace {
    border-color: #d6dbdf;
  }
  .dk-bg .inp-sgt .tags > li:hover,
  .dk-bg .inp-sgt .tags > li:focus {
    background-color: #292929;
    color: #ffffff;
  }
}
:root.high-contrast {
}
html.high-contrast :root {
}
html.high-contrast body .lt-bg .btn.v1,
html.high-contrast body .dk-bg .btn.v1,
html.high-contrast .dk-bg .bg-bx.ulk-bg .lk-bg .btn.v1 {
  color: #a0a0a0;
}
:root {
}
button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  background-color: transparent;
}
.btn {
  cursor: pointer;
  position: relative;
}
.btn.v1 {
  z-index: 1;
  overflow: hidden;
  text-align: center;
  display: inline-flex;
  align-items: center;
  padding: 0.25em 2em 0.25em 0em;
  transition: color 0.3s ease 0s;
}
body .lt-bg .btn.v1,
body .dk-bg .btn.v1,
.dk-bg .bg-bx.ulk-bg .lk-bg .btn.v1 {
  color: #a0a0a0;
}
.lt-bg .bg-bx.lk-bg .btn.v1,
.dk-bg .bg-bx.ulk-bg .btn.v1 {
  color: #ffffff;
}
.btn.v1 span {
  top: 1px;
  flex: 1 1 auto;
  position: relative;
}
.btn.v1::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  clip-path: polygon(
    45% 0%,
    55% 0%,
    55% 45%,
    100% 45%,
    100% 55%,
    55% 55%,
    55% 100%,
    45% 100%,
    45% 55%,
    0% 55%,
    0% 45%,
    45% 45%
  );
  width: 0.9rem;
  height: 0.9rem;
  transition: transform 0.3s ease 0s;
}
.lt-bg .btn.v1::before,
.dk-bg .bg-bx.ulk-bg .btn.v1::before {
  background-color: #191919;
}
.dk-bg .btn.v1::before,
.lt-bg .bg-bx.ulk-bg .btn.v1::before,
.dk-bg .bg-bx.ulk-bg .lk-bg .btn.v1::before {
  background-color: #ffffff;
}
.btn.v2 {
  z-index: 1;
  display: inline-block;
  transition: color 0.3s ease 0s;
}
.lt-bg .btn.v2,
.lt-bg .bg-bx.ulk-bg .btn.v2,
.dk-bg .btn.v2 {
  color: #a0a0a0;
}
.lt-bg .bg-bx.lk-bg .btn.v2,
.dk-bg .bg-bx.ulk-bg .btn.v2 {
  color: #a0a0a0;
}
@media screen and (min-width: 1025px) {
  .btn:not(.no-hvr).v1:hover::before,
  .btn:not(.no-hvr).v1:focus::before,
  a:hover .btn:not(.no-hvr).v1::before,
  a:focus .btn:not(.no-hvr).v1::before {
    transform: translateY(-50%) rotate(45deg);
  }
  .lt-bg .btn:not(.no-hvr).v1:hover,
  .lt-bg .btn:not(.no-hvr).v1:focus,
  .lt-bg a:hover .btn:not(.no-hvr).v1,
  .lt-bg a:focus .btn:not(.no-hvr).v1,
  .dk-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v1:hover,
  .dk-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v1:focus,
  .dk-bg .bg-bx.ulk-bg a:hover .btn:not(.no-hvr).v1,
  .dk-bg .bg-bx.ulk-bg a:focus .btn:not(.no-hvr).v1 {
    color: #191919;
  }
  .dk-bg .btn:not(.no-hvr).v1:hover,
  .dk-bg .btn:not(.no-hvr).v1:focus,
  .dk-bg a:hover .btn:not(.no-hvr).v1,
  .dk-bg a:focus .btn:not(.no-hvr).v1 {
    color: #ffffff;
  }
  .lt-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v1:hover,
  .lt-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v1:focus,
  .lt-bg .bg-bx.ulk-bg a:hover .btn:not(.no-hvr).v1,
  .lt-bg .bg-bx.ulk-bg a:focus .btn:not(.no-hvr).v1,
  .dk-bg .bg-bx.lk-bg .btn:not(.no-hvr).v1:hover,
  .dk-bg .bg-bx.lk-bg .btn:not(.no-hvr).v1:focus,
  .dk-bg .bg-bx.lk-bg a:hover .btn:not(.no-hvr).v1,
  .dk-bg .bg-bx.lk-bg a:focus .btn:not(.no-hvr).v1 {
    color: #ffffff;
  }
  .lt-bg .btn:not(.no-hvr).v2:hover,
  .lt-bg .btn:not(.no-hvr).v2:focus,
  .lt-bg a:hover .btn:not(.no-hvr).v2,
  .lt-bg a:focus .btn:not(.no-hvr).v2,
  .dk-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v2:hover,
  .dk-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v2:focus,
  .dk-bg .bg-bx.ulk-bg a:hover .btn:not(.no-hvr).v2,
  .dk-bg .bg-bx.ulk-bg a:focus .btn:not(.no-hvr).v2 {
    color: #191919;
  }
  .dk-bg .btn:not(.no-hvr).v2:hover,
  .dk-bg .btn:not(.no-hvr).v2:focus,
  .dk-bg a:hover .btn:not(.no-hvr).v2,
  .dk-bg a:focus .btn:not(.no-hvr).v2,
  .lt-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v2:hover,
  .lt-bg .bg-bx.ulk-bg .btn:not(.no-hvr).v2:focus,
  .lt-bg .bg-bx.ulk-bg a:hover .btn:not(.no-hvr).v2,
  .lt-bg .bg-bx.ulk-bg a:focus .btn:not(.no-hvr).v2 {
    color: #ffffff;
  }
}
:root {
}
.bx_flr {
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #a0a0a0;
  padding: 1rem;
}
:root {
}
[data-parallax-item] {
  transition: all 0.3s ease-out;
}
@media screen and (min-width: 1025px) {
  .anm_img img,
  .anm_seq-imgs > picture img,
  .anm_hlf-lt,
  .anm_hlf-rt,
  .anm_hlf-up,
  .anm_hlf-dn,
  .anm_seq-lst > li {
    opacity: 0;
    transition: opacity 1s ease 0s, transform 1s ease 0s;
  }
  .show-20 .anm_img img,
  .show-20 .anm_seq-imgs > picture img,
  .show-20 .anm_hlf-lt,
  .show-20 .anm_hlf-rt,
  .show-20 .anm_hlf-up,
  .show-20 .anm_hlf-dn,
  .show-20 .anm_seq-itm > *,
  .show-20 .anm_seq-lst > li {
    opacity: 1;
    transform: none;
  }
  .anm_hlf-lt {
    transform: translateX(2rem);
  }
  .anm_hlf-rt {
    transform: translateX(calc(2rem * -1));
  }
  .anm_hlf-up {
    transform: translateY(2rem);
  }
  .anm_hlf-dn {
    transform: translateY(calc(2rem * -1));
  }
  [class*="anm_seq-itm"] > *:first-child {
    transition-delay: calc(0s + 0.3s);
  }
  [class*="anm_seq-itm"] > *:nth-child(2) {
    transition-delay: calc(0s + (0.3s * 2));
  }
  [class*="anm_seq-itm"] > *:nth-child(3) {
    transition-delay: calc(0s + (0.3s * 3));
  }
  [class*="anm_seq-itm"] > *:nth-child(4) {
    transition-delay: calc(0s + (0.3s * 4));
  }
  [class*="anm_seq-itm"] > *:nth-child(5) {
    transition-delay: calc(0s + (0.3s * 5));
  }
  .anm_seq-lst > li:first-child {
    transition-delay: calc(0s + 0.2s);
  }
  .anm_seq-lst > li:nth-child(2) {
    transition-delay: calc(0s + (0.2s * 2));
  }
  .anm_seq-lst > li:nth-child(3) {
    transition-delay: calc(0s + (0.2s * 3));
  }
  .anm_seq-lst > li:nth-child(4) {
    transition-delay: calc(0s + (0.2s * 4));
  }
  .anm_seq-lst > li:nth-child(5) {
    transition-delay: calc(0s + (0.2s * 5));
  }
  .anm_seq-lst > li:nth-child(6) {
    transition-delay: calc(0s + (0.2s * 6));
  }
  .anm_seq-lst > li:nth-child(7) {
    transition-delay: calc(0s + (0.2s * 7));
  }
  .anm_seq-lst > li:nth-child(8) {
    transition-delay: calc(0s + (0.2s * 8));
  }
  .anm_seq-lst > li:nth-child(9) {
    transition-delay: calc(0s + (0.2s * 9));
  }
  .anm_seq-lst > li:nth-child(10) {
    transition-delay: calc(0s + (0.2s * 10));
  }
  .anm_seq-lst > li:nth-child(11) {
    transition-delay: calc(0s + (0.2s * 11));
  }
  .anm_seq-lst > li:nth-child(12) {
    transition-delay: calc(0s + (0.2s * 12));
  }
  .anm_seq-lst > li:nth-child(13) {
    transition-delay: calc(0s + (0.2s * 13));
  }
  .anm_seq-lst > li:nth-child(14) {
    transition-delay: calc(0s + (0.2s * 14));
  }
  .anm_seq-lst > li:nth-child(15) {
    transition-delay: calc(0s + (0.2s * 15));
  }
  .anm_seq-lst > li:nth-child(16) {
    transition-delay: calc(0s + (0.2s * 16));
  }
  .anm_seq-lst > li:nth-child(17) {
    transition-delay: calc(0s + (0.2s * 17));
  }
  .anm_seq-lst > li:nth-child(18) {
    transition-delay: calc(0s + (0.2s * 18));
  }
  .anm_seq-lst > li:nth-child(19) {
    transition-delay: calc(0s + (0.2s * 19));
  }
  .anm_seq-lst > li:nth-child(20) {
    transition-delay: calc(0s + (0.2s * 20));
  }
  .anm_seq-lst > li:nth-child(21) {
    transition-delay: calc(0s + (0.2s * 21));
  }
  .anm_seq-lst > li:nth-child(22) {
    transition-delay: calc(0s + (0.2s * 22));
  }
  .anm_seq-lst > li:nth-child(23) {
    transition-delay: calc(0s + (0.2s * 23));
  }
  .anm_seq-lst > li:nth-child(24) {
    transition-delay: calc(0s + (0.2s * 24));
  }
  .anm_seq-lst > li:nth-child(25) {
    transition-delay: calc(0s + (0.2s * 25));
  }
  .anm_seq-lst > li:nth-child(26) {
    transition-delay: calc(0s + (0.2s * 26));
  }
  .anm_seq-lst > li:nth-child(27) {
    transition-delay: calc(0s + (0.2s * 27));
  }
  .anm_seq-lst > li:nth-child(28) {
    transition-delay: calc(0s + (0.2s * 28));
  }
  .anm_seq-lst > li:nth-child(29) {
    transition-delay: calc(0s + (0.2s * 29));
  }
  .anm_seq-lst > li:nth-child(30) {
    transition-delay: calc(0s + (0.2s * 30));
  }
}
:root {
}
.ui-scroll {
  overflow: auto;
}
.ui-dialog .ui-dialog-titlebar-close {
  z-index: 3;
}
section {
  overflow: visible !important;
}
body {
  overflow-x: hidden;
}
.psu-bfr,
.psu-aft,
.bg-bx {
  z-index: 2 !important;
}
img {
  max-width: 100%;
}
address {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 2;
  font-size: 1.11rem;
}
.ints {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ftr-i {
  position: absolute;
  top: 0;
  right: 7%;
  z-index: 2;
  font-size: 2.67rem;
}
.itm {
  transition: all 0.3s ease 0s;
}
.op-itm.itm,
.op-itm .itm {
  opacity: 1;
}
.op-in .itm {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: auto;
  visibility: hidden;
  transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
}
.hvr_scl-itm,
.scl-img-itm .itm {
  transform: none;
  transform-origin: center;
  transition: all 0.3s ease 0s;
}
.bg-bx.btn-clr-hvr::before {
  transition: background-color 0.3s ease 0s;
}
.so-lk {
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
}
[data-src],
[data-bg],
[data-src].loading,
[data-bg].loading {
  filter: none;
  -webkit-filter: none;
}
.bx_shw-itm:not(.bx_flr) {
  box-shadow: 0rem 0.167rem 0.333rem rgba(0, 0, 0, 0.5);
}
[class*="bg-shf"] .img-bg {
  z-index: 0 !important;
}
body .ui-dialog.popup {
  font-family: "Georama", sans-serif;
}
.dk-lg.sp,
.lt-lg.sp {
  display: none;
}
.lt-bg .bg-bx.ulk-bg .lt-lg.sp {
  display: block;
}
.dk-bg .bg-bx.ulk-bg .dk-lg.sp {
  display: block;
}
section header > *:nth-child(2):not(p) {
  margin-top: 0.5em;
}
.cnt-stl .txt-hlt:not(:first-child),
.cnt-stl .spl-lst:not(:first-child) {
  margin-top: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.45
  );
}
.cnt-stl .txt-hlt:not(:last-child),
.cnt-stl .spl-lst:not(:first-child) {
  margin-bottom: calc(
    calc((30 * 1px) + (45 - 30) * ((100vw - 320px) / (1920 - 320))) * 0.45
  );
}
h1 u,
h2 u,
h3 u,
h4 u,
h5 u,
h6 u,
section header u,
[class*="fnt_t"] u,
.cnt-stl h1 strong,
.cnt-stl h2 strong,
.cnt-stl h3 strong,
.cnt-stl h4 strong,
.cnt-stl h5 strong,
.cnt-stl h6 strong {
  font-weight: 700;
  text-decoration: none;
}
.cnt-stl ul {
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
}
.cnt-stl > ul:not(.spl-lst) {
  padding-left: 1.333em;
}
.cnt-stl ol {
  list-style-type: decimal;
  margin: 1em 0;
  padding: 0 0 0 1.5em;
}
.cnt-stl ol ol,
.cnt-stl ul ul {
  margin: 0;
}
.cnt-stl ul li {
  position: relative;
  padding: 0 0 0 1.5em;
}
.cnt-stl ul:not(.spl-lst) li + li {
  margin-top: 0.7em;
}
.cnt-stl ul ul {
  margin-top: 0.25em;
}
.cnt-stl ul li::before {
  content: "";
  position: absolute;
  top: 0.65em;
  left: 0;
  width: 0.889em;
  height: 0.889em;
  -webkit-clip-path: polygon(20% 20%, 80% 20%, 80% 80%, 20% 80%);
  clip-path: polygon(20% 20%, 80% 20%, 80% 80%, 20% 80%);
}
.lt-bg .cnt-stl ul li::before,
.dk-bg .bg-bx.ulk-bg .cnt-stl ul li::before {
  background-color: #000000;
}
.dk-bg .cnt-stl ul li::before,
.lt-bg .bg-bx.ulk-bg .cnt-stl ul li::before {
  background-color: #d6dbdf;
}
.cnt-stl > p + p {
  margin-top: 2rem;
}
.abs-mdl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@supports (-ms-ime-align: auto) {
  [class*="flx"][class*="-grd"]:not([class*="-lrg"]) > li {
    margin: calc((1.8rem * 0.5) - 2px) !important;
  }
  [class*="flx"][class*="-sml"][class*="-grd"] > li {
    margin: calc((1.8rem * 0.7) - 2px) !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cnt-stl .txt-hlt:not(:first-child) {
    margin-top: 45px;
  }
  .cnt-stl .txt-hlt:not(:last-child) {
    margin-bottom: 45px;
  }
  .pop .bx {
    max-width: 64rem;
  }
}
@media screen and (min-width: 501px) {
  .cnt-stl .spl-lst li {
    width: 47%;
  }
  .cnt-stl .spl-lst li:nth-child(n + 2) ~ li {
    margin-top: 0.7em;
  }
}
@media screen and (min-width: 1025px) {
  .hlf-sm {
    width: calc(50% - (4.8% * 2.75));
  }
  .hlf-md {
    width: calc(50% - (4.8% * 2.25));
  }
  .hlf-big {
    width: calc(50% + 4.8%);
  }
  .op-in:hover .itm,
  .op-in:focus .itm {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease 0s, visibility 0s ease 0s;
  }
  .op-itm.itm:hover,
  .op-itm.itm:focus,
  .op-itm:hover .itm,
  .op-itm:focus .itm {
    opacity: 0.55;
  }
  .hvr_scl-itm:hover,
  .hvr_scl-itm:focus,
  .scl-img-itm:hover .itm,
  .scl-img-itm:focus .itm {
    transform: scale(1.05, 1.05);
  }
  .bx_shw,
  .bx_shw .bx_flr {
    overflow: visible;
  }
  .bx_shw:not(.bx_flr),
  .bx_shw.bx_flr::before {
    box-shadow: 0rem 0.167rem 0.333rem rgba(0, 0, 0, 0.5);
  }
  .blg-hvr {
    padding-right: calc((2.5em * 2) + 0.389em);
    padding-left: 0;
    box-shadow: none;
    border-left-style: solid;
    border-left-width: 0.389em;
    transition: padding 0.3s ease 0s, box-shadow 0.3s ease 0s,
      background-color 0.3s ease 0s, color 0.3s ease 0s,
      border-color 0.3s ease 0s;
  }
  .blg-hvr:hover,
  .blg-hvr:focus {
    padding-right: 2.5em;
    padding-left: 2.5em;
    box-shadow: 0rem 0.167rem 0.333rem rgba(0, 0, 0, 0.5);
  }
  .blg-hvr .itm {
    transform: translateX(calc(0.389em * -1));
    transition: transform 0.3s ease 0s;
  }
  .blg-hvr:hover .itm,
  .blg-hvr:focus .itm {
    transform: none;
  }
  .ply-btn.bx_flr:hover,
  .ply-btn.bx_flr:focus {
    border-color: #ffffff !important;
    box-shadow: 0rem 0.167rem 0.333rem #ffffff,
      inset 0rem 0.167rem 0.333rem #ffffff;
    background-color: #292929;
  }
  .bg-shf-r .img-bg {
    width: calc(50% + calc(4.8% * 2));
    right: 0;
    left: auto;
  }
  .bg-shf-l .img-bg {
    width: calc(50% + calc(4.8% * 2));
    left: 0;
  }
  .lt-bg[class*="bg-shf-"] {
    background-color: #292929;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1025px) {
  fieldset [class*="flx"][class*="-grd"] > li.half {
    max-width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .lt-bg.rsp_opn-bt + .lt-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp,
  .dk-bg.rsp_opn-bt + .dk-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp {
    padding-top: 1px !important;
  }
  .dk-bg:not(.alt-bg).rsp_opn-bt + .dk-bg.alt-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp,
  .dk-bg.alt-bg.rsp_opn-bt + .dk-bg.rsp_opn-tp .rsp_pd.rsp_opn-tp {
    padding-top: calc(
      (50 * 1px) + (100 - 50) * ((100vw - 320px) / (1920 - 320))
    ) !important;
  }
  .cnt-stl > ul:not(.spl-lst) {
    padding-left: 1.5em;
  }
  .cnt-stl .spl-lst {
    padding-left: 1.5em;
  }
  .bdr_psu::before {
    display: none;
  }
  .wd-fll-1024 {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .cnt-stl .spl-lst li {
    margin-top: 0.5em;
    width: 100%;
  }
}
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
fieldset legend {
  padding: 0;
}
fieldset > ul li {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  font-size: inherit;
}
fieldset li.full {
  width: 100% !important;
}
fieldset li label {
  display: block;
  font-size: 14px;
  text-align: left;
  transition: color 0.3s ease 0s;
}
input,
textarea,
select {
  border: 0;
  width: 100%;
  height: 100%;
  outline: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  font-family: inherit;
  line-height: inherit;
  text-transform: none;
  background-color: transparent;
}
select,
.input-text,
input[type="search"] {
  appearance: none;
  -mox-appearance: none;
  -webkit-appearance: none;
}
textarea {
  resize: none;
  overflow: auto;
}
select::-ms-expand {
  display: none;
}
:invalid,
input:invalid,
:-moz-ui-invalid,
:-moz-submit-invalid {
  box-shadow: none;
}
.inp-sgt,
.inp-mrk,
.input-text {
  position: relative;
}
.input-text {
  width: 100%;
  font-size: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0rem;
  background-clip: padding-box !important;
  transition: background-color 0.3s ease 0s, border-color 0.3s ease 0s,
    color 0.3s ease 0s;
}
.inp-mrk .input-text {
  width: auto;
}
li.msg textarea {
  min-height: 6.333rem;
}
.sel svg {
  right: 0.5rem;
  font-size: 1rem;
  position: absolute;
  pointer-events: none;
  color: #a0a0a0;
}
.input-text select {
  padding-right: 1rem;
}
.lt-bg .input-text select option,
.dk-bg .input-text select option {
  background-color: #ffffff;
}
input[id*="Address"]::placeholder {
  opacity: 0;
}
.inp-sgt .sgts {
  position: absolute;
  left: 50%;
  top: calc(0% + 2.5rem);
  width: 100%;
  z-index: 1;
  opacity: 0;
  border-width: 2px;
  border-style: solid;
  margin-top: 0.778rem;
  pointer-events: none;
  transform: translateX(-50%);
  transition: opacity 0.3s ease 0s, transform 0.3s ease 0s,
    pointer-events 0s ease 0s;
}
.inp-sgt.focus .sgts,
.inp-sgt input:focus + .sgts {
  opacity: 1;
  pointer-events: all;
}
.inp-sgt .sgts > ul {
  padding: 0.778rem;
  max-height: 22.222rem;
}
.inp-sgt .sgts > ul li:not(:last-child) {
  padding: 0 0 0.778rem 0;
}
fieldset .sgt .inp-sgt .tgs {
  margin-top: 1rem;
}
fieldset .sgt .inp-sgt .tgs > li {
  flex: 0 0 auto;
}
.inp-sgt .tgs > li {
  display: flex;
  line-height: 1.2;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.7em 0.5em 1em;
  transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
}
.inp-sgt .tgs > li .remove,
.inp-sgt .tgs > li [data-role="remove"] {
  width: 0.778em;
  height: 0.778em;
  cursor: pointer;
  position: relative;
  margin: 0 0 0 0.4rem;
}
.inp-sgt .tgs > li .remove:before,
.inp-sgt .tgs > li .remove:after,
.inp-sgt .tgs > li [data-role="remove"]:before,
.inp-sgt .tgs > li [data-role="remove"]:after {
  content: "";
  top: 50%;
  left: 50%;
  height: 2px;
  width: 100%;
  display: block;
  position: absolute;
  margin: -2px 0 0 -40%;
}
.inp-sgt .tgs > li .remove:before,
.inp-sgt .tgs > li [data-role="remove"]:before {
  transform: rotate(45deg);
}
.inp-sgt .tgs > li .remove:after,
.inp-sgt .tgs > li [data-role="remove"]:after {
  transform: rotate(-45deg);
}
.inp-mrk .input-text {
  border: 0;
  padding: 0;
}
.inp-mrk input[type="radio"],
.inp-mrk input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 3;
}
.inp-mrk label {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.inp-mrk label.toggle,
.inp-mrk label.replace {
  flex: 0 0 auto;
  cursor: pointer;
}
.inp-mrk label.replace {
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border-width: 2px;
  position: relative;
  border-style: solid;
  transition: border-color 0.3s ease 0s;
}
.inp-mrk [type="radio"] + label.replace {
  border-radius: 50%;
}
.inp-mrk label.replace:before {
  content: "";
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  opacity: 0;
  position: absolute;
  transform: scale(0.7);
  transition: background-color 0.3s ease 0s, transform 0.3s ease 0s,
    opacity 0.3s ease 0s;
}
.inp-mrk [type="radio"] + label.replace:before {
  border-radius: 50%;
}
.inp-mrk input:checked + label.replace:before {
  opacity: 1;
  transform: none;
}
.inp-mrk label.replace + label,
.inp-mrk label.toggle + label {
  margin: 0;
  line-height: 1.55;
  position: relative;
  padding-left: 0.625em;
}
.inp-mrk .mini label + label {
  font-size: 0.875em;
}
.validation {
  right: 0;
  top: 100%;
  opacity: 0;
  z-index: 2;
  display: block;
  font-size: 0.8em;
  padding: 0.5em 1em;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  transition: opacity 0.3s ease 0s, visibility 0s ease 0s;
}
.invalid .validation {
  opacity: 1;
  visibility: visible;
}
.lt-bg .validation {
  color: #191919;
  background: rgba(0, 0, 0, 0.8);
}
.dk-bg .validation {
  color: #191919;
  background: rgba(0, 0, 0, 0.8);
}
.invalid[data-validity="valueMissing"]
  .validation:not([data-type="valueMissing"]) {
  opacity: 0;
  visibility: hidden;
}
@media screen and (min-width: 501px) {
  fieldset > ul > li > ul > li.half:nth-last-child(2) {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1025px) {
  .sel svg {
    bottom: 0.8rem;
  }
}
@media screen and (max-width: 1024px) {
  .sel svg {
    top: 50%;
    transform: translateY(-50%);
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  [class*="mn_"] {
    padding-left: 140px;
    padding-right: 140px;
  }
  .mn_wd {
    max-width: calc(91.333rem + 140px * 2);
  }
  .mn_tn {
    max-width: calc(64.41rem + 140px * 2);
  }
  .sd-zn > * + *,
  .cnt-zn > * + * {
    margin-top: 45px;
  }
  .pd_v {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .pd_v-10 {
    padding-top: calc(100px * 0.2);
    padding-bottom: calc(100px * 0.2);
  }
  .pd_v-20 {
    padding-top: calc(100px * 0.4);
    padding-bottom: calc(100px * 0.4);
  }
  .pd_v-30 {
    padding-top: calc(100px * 0.6);
    padding-bottom: calc(100px * 0.6);
  }
  .pd_v-40 {
    padding-top: calc(100px * 0.8);
    padding-bottom: calc(100px * 0.8);
  }
  .pd_v-60 {
    padding-top: calc(100px * 1.2);
    padding-bottom: calc(100px * 1.2);
  }
  .pd_v-70 {
    padding-top: calc(100px * 1.4);
    padding-bottom: calc(100px * 1.4);
  }
  .pd_v-80 {
    padding-top: calc(100px * 1.6);
    padding-bottom: calc(100px * 1.6);
  }
  .pd_v-90 {
    padding-top: calc(100px * 1.8);
    padding-bottom: calc(100px * 1.8);
  }
  .pd_h {
    padding-left: 140px;
    padding-right: 140px;
  }
  .pd_h-10 {
    padding-left: calc(140px * 0.2);
    padding-right: calc(140px * 0.2);
  }
  .pd_h-20 {
    padding-left: calc(140px * 0.4);
    padding-right: calc(140px * 0.4);
  }
  .pd_h-30 {
    padding-left: calc(140px * 0.6);
    padding-right: calc(140px * 0.6);
  }
  .pd_h-40 {
    padding-left: calc(140px * 0.8);
    padding-right: calc(140px * 0.8);
  }
  .pd_h-60 {
    padding-left: calc(140px * 1.2);
    padding-right: calc(140px * 1.2);
  }
  .pd_h-70 {
    padding-left: calc(140px * 1.4);
    padding-right: calc(140px * 1.4);
  }
  .pd_h-80 {
    padding-left: calc(140px * 1.6);
    padding-right: calc(140px * 1.6);
  }
  .pd_h-90 {
    padding-left: calc(140px * 1.8);
    padding-right: calc(140px * 1.8);
  }
  .mrg_tp {
    margin-top: 45px;
  }
  .mrg_tp-10 {
    margin-top: calc(45px * 0.2);
  }
  .mrg_tp-20 {
    margin-top: calc(45px * 0.4);
  }
  .mrg_tp-30 {
    margin-top: calc(45px * 0.6);
  }
  .mrg_tp-40 {
    margin-top: calc(45px * 0.8);
  }
  .mrg_tp-60 {
    margin-top: calc(45px * 1.2);
  }
  .mrg_tp-70 {
    margin-top: calc(45px * 1.4);
  }
  .mrg_tp-80 {
    margin-top: calc(45px * 1.6);
  }
  .mrg_tp-90 {
    margin-top: calc(45px * 1.8);
  }
  .mrg_bt {
    margin-bottom: 45px;
  }
  .mrg_bt-10 {
    margin-bottom: calc(45px * 0.2);
  }
  .mrg_bt-20 {
    margin-bottom: calc(45px * 0.4);
  }
  .mrg_bt-30 {
    margin-bottom: calc(45px * 0.6);
  }
  .mrg_bt-40 {
    margin-bottom: calc(45px * 0.8);
  }
  .mrg_bt-60 {
    margin-bottom: calc(45px * 1.2);
  }
  .mrg_bt-70 {
    margin-bottom: calc(45px * 1.4);
  }
  .mrg_bt-80 {
    margin-bottom: calc(45px * 1.6);
  }
  .mrg_bt-90 {
    margin-bottom: calc(45px * 1.8);
  }
}
[class*="flx"][class*="-grd"][class*="-sml"] {
  width: calc(100% + (1.8rem * 0.7));
  left: calc((1.8rem * 0.7) * -0.5);
  margin-top: calc((1.8rem * 0.7) * -0.5);
  margin-bottom: calc((1.8rem * 0.7) * -0.5);
}
[class*="flx"][class*="-grd"][class*="-sml"] > li {
  margin: calc(((1.8rem * 0.7) - 0.111rem) * 0.5);
}
[class*="flx"][class*="-grd"][class*="-sml"] > li.full {
  max-width: calc(100% - (1.8rem * 0.7));
}
[class*="flx"][class*="-grd"][class*="-sml"] > li.half {
  max-width: calc(50% - (1.8rem * 0.7));
}
[class*="flx"][class*="-grd"][class*="-sml"] > li.third {
  max-width: calc(33.333% - (1.8rem * 0.7));
}
[class*="flx"][class*="-grd"][class*="-sml"] > li.fourth {
  max-width: calc(25% - (1.8rem * 0.7));
}
[class*="flx"][class*="-grd"][class*="-sml"] > li.fifth {
  max-width: calc(20% - (1.8rem * 0.7));
}
[class*="flx"][class*="-grd"][class*="-sml"] > li.sixth {
  max-width: calc(16.666% - (1.8rem * 0.7));
}
[class*="flx"][class*="-grd"][class*="-sml"] > li.seventh {
  max-width: calc(14.285% - (1.8rem * 0.7));
}
[class*="ato-sz"][class*="flx"][class*="-grd"][class*="-sml"] > li {
  width: calc(14.285% - (1.8rem * 0.7));
}
[class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]
  > li:nth-last-of-type(2n):first-of-type,
[class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]
  > li:nth-last-of-type(2n):first-of-type
  ~ li {
  width: calc(50% - (1.8rem * 0.7));
}
@media screen and (min-width: 1025px) {
  [class*="ato-sz"][class*="flx"][class*="mx-4"] > li {
    width: 25%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-4"] > li {
    width: calc(25% - 1.8rem);
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-4"][class*="-sml"]
    > li {
    width: calc(25% - (1.8rem * 0.7));
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-4"][class*="-lrg"]
    > li {
    width: calc(25% - (1.8rem * 1.5));
  }
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type
    ~ li {
    width: 25%;
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type
    ~ li {
    width: calc(25% - 1.8rem);
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n):first-of-type
    ~ li {
    width: calc(25% - (1.8rem * 0.7));
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n):first-of-type
    ~ li {
    width: calc(25% - (1.8rem * 1.5));
  }
}
@media screen and (min-width: 1281px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-3"][class*="-sml"]
    > li {
    width: calc(33.333% - (1.8rem * 0.7));
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]
    > li:nth-last-of-type(3n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]
    > li:nth-last-of-type(3n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]
    > li:nth-last-of-type(3n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]
    > li:nth-last-of-type(3n):first-of-type
    ~ li {
    width: calc(33.333% - (1.8rem * 0.7));
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-5"][class*="-sml"]
    > li {
    width: calc(20% - (1.8rem * 0.7));
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n):first-of-type
    ~ li {
    width: calc(20% - (1.8rem * 0.7));
  }
}
@media screen and (min-width: 1601px) {
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type
    ~ li {
    width: calc(16.666% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 1600px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-sml"] > li {
    width: calc(20% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 1440px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-sml"] > li {
    width: calc(25% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 1280px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-sml"] > li {
    width: calc(33.333% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 1024px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-sml"] > li {
    width: calc(50% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 800px) {
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-sml"] > li:not(#_) {
    width: calc(50% - (1.8rem * 0.7));
  }
}
[class*="flx"][class*="-grd"] {
  flex-wrap: wrap;
  position: relative;
  width: calc(100% + 1.8rem);
  left: calc(1.8rem * -0.5);
  margin-top: calc(1.8rem * -0.5);
  margin-bottom: calc(1.8rem * -0.5);
}
[class*="flx"][class*="-grd"] > li {
  margin: calc((1.8rem - 0.111rem) * 0.5);
  flex: 1 1 auto;
}
[class*="flx"][class*="-grd"] > li.fit {
  flex: 0 1 auto;
}
[class*="flx"][class*="-grd"] > li.full {
  max-width: calc(100% - 1.8rem);
}
[class*="flx"][class*="-grd"] > li.half {
  max-width: calc(50% - 1.8rem);
}
[class*="flx"][class*="-grd"] > li.third {
  max-width: calc(33.333% - 1.8rem);
}
[class*="flx"][class*="-grd"] > li.fourth {
  max-width: calc(25% - 1.8rem);
}
[class*="flx"][class*="-grd"] > li.fifth {
  max-width: calc(20% - 1.8rem);
}
[class*="flx"][class*="-grd"] > li.sixth {
  max-width: calc(16.666% - 1.8rem);
}
[class*="flx"][class*="-grd"] > li.seventh {
  max-width: calc(14.285% - 1.8rem);
}
[class*="ato-sz"][class*="flx"] > li {
  width: 14.285%;
}
[class*="ato-sz"][class*="flx"][class*="-grd"] > li {
  width: calc(14.285% - 1.8rem);
}
[class*="ato-sz"][class*="flx-"] > li:nth-last-of-type(2n):first-of-type,
[class*="ato-sz"][class*="flx-"] > li:nth-last-of-type(2n):first-of-type ~ li {
  width: 50%;
}
[class*="ato-sz"][class*="flx-"][class*="-grd"]
  > li:nth-last-of-type(2n):first-of-type,
[class*="ato-sz"][class*="flx-"][class*="-grd"]
  > li:nth-last-of-type(2n):first-of-type
  ~ li {
  width: calc(50% - 1.8rem);
}
@media screen and (min-width: 1025px) {
  [class*="ato-sz"][class*="flx"][class*="mx-4"] > li {
    width: 25%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-4"] > li {
    width: calc(25% - 1.8rem);
  }
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type
    ~ li {
    width: 25%;
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-3"])
    > li:nth-last-of-type(4n):first-of-type
    ~ li {
    width: calc(25% - 1.8rem);
  }
}
@media screen and (min-width: 1281px) {
  [class*="ato-sz"][class*="flx"][class*="mx-3"] > li {
    width: 33.333%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-3"] > li {
    width: calc(33.333% - 1.8rem);
  }
  [class*="ato-sz"][class*="flx-"] > li:nth-last-of-type(3n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"]
    > li:nth-last-of-type(3n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"] > li:nth-last-of-type(3n):first-of-type,
  [class*="ato-sz"][class*="flx-"]
    > li:nth-last-of-type(3n):first-of-type
    ~ li {
    width: 33.333%;
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"]
    > li:nth-last-of-type(3n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]
    > li:nth-last-of-type(3n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]
    > li:nth-last-of-type(3n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]
    > li:nth-last-of-type(3n):first-of-type
    ~ li {
    width: calc(33.333% - 1.8rem);
  }
  [class*="ato-sz"][class*="flx"][class*="mx-5"] > li {
    width: 20%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-5"] > li {
    width: calc(20% - 1.8rem);
  }
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(5n):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(5n):first-of-type
    ~ li {
    width: 20%;
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-4"]):not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(5n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-4"]):not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(5n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-4"]):not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(5n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-4"]):not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(5n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-4"]):not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(5n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-4"]):not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(5n):first-of-type
    ~ li {
    width: calc(20% - 1.8rem);
  }
}
@media screen and (min-width: 1601px) {
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type,
  [class*="ato-sz"][class*="flx-"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type
    ~ li {
    width: 16.666%;
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"]:not([class*="mx-5"]):not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type
    ~ li {
    width: calc(16.666% - 1.8rem);
  }
}
@media screen and (max-width: 1600px) {
  [class*="ato-sz"][class*="flx"] > li {
    width: 20%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"] > li {
    width: calc(20% - 1.8rem);
  }
}
@media screen and (max-width: 1440px) {
  [class*="ato-sz"][class*="flx"] > li {
    width: 25%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"] > li {
    width: calc(25% - 1.8rem);
  }
}
@media screen and (max-width: 1280px) {
  [class*="flx"][class*="-blk-1280"][class*="-grd"] {
    width: 100% !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [class*="flx"][class*="-blk-1280"][class*="-grd"] > li:not(#_) {
    max-width: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  [class*="ato-sz"][class*="flx"] > li {
    width: 33.333%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"] > li {
    width: calc(33.333% - 1.8rem);
  }
}
@media screen and (max-width: 1024px) {
  [class*="flx"][class*="-blk-1024"][class*="-grd"] {
    width: 100% !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [class*="flx"][class*="-blk-1024"][class*="-grd"] > li:not(#_) {
    max-width: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  [class*="ato-sz"][class*="flx"] > li {
    width: 50%;
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"] > li {
    width: calc(50% - 1.8rem);
  }
  .cls-gp-1024 > *:first-child,
  .cls-gp-1024 > .cms-repeater-placeholder:first-child ~ *:nth-child(2) {
    margin-top: 0 !important;
  }
  .cls-gp-1024 > *:last-child,
  .cls-gp-1024 > .cms-repeater-placeholder ~ *:nth-last-child(2) {
    margin-bottom: 0 !important;
  }
  .cls-gp-all-1024 > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 800px) {
  [class*="flx"][class*="-blk-800"][class*="-grd"],
  [class*="-sml"][class*="flx"][class*="-blk-800"][class*="-grd"] {
    width: 100% !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [class*="flx"][class*="-blk-800"][class*="-grd"] > li:not(#_) {
    max-width: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  [class*="ato-sz"][class*="flx-"] > li:not(#_) {
    width: 50%;
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"] > li:not(#_) {
    width: calc(50% - 1.8rem);
  }
  .cls-gp-800 > *:first-child,
  .cls-gp-800 > .cms-repeater-placeholder:first-child ~ *:nth-child(2) {
    margin-top: 0 !important;
  }
  .cls-gp-800 > *:last-child,
  .cls-gp-800 > .cms-repeater-placeholder ~ *:nth-last-child(2) {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  [class*="flx"][class*="-blk-500"][class*="-grd"],
  [class*="-sml"][class*="flx"][class*="-blk-500"][class*="-grd"] {
    width: 100% !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [class*="flx"][class*="-blk-500"][class*="-grd"] > li:not(#_) {
    max-width: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .cls-gp-500 > *:first-child,
  .cls-gp-500 > .cms-repeater-placeholder:first-child ~ *:nth-child(2) {
    margin-top: 0 !important;
  }
  .cls-gp-500 > *:last-child,
  .cls-gp-500 > .cms-repeater-placeholder ~ *:nth-last-child(2) {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 380px) {
  [class*="flx"][class*="-blk-380"][class*="-grd"] {
    width: 100% !important;
    left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  [class*="flx"][class*="-blk-380"][class*="-grd"] > li:not(#_) {
    max-width: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.ctc-sys.v1 {
}
.ctc-sys.v1 .mp-bx {
  height: 100%;
}
.ctc-sys.v1 .mp-bx .static-map {
  width: 100%;
}
.ctc-sys.v1 .loc-lst {
  max-height: 26rem;
}
@media screen and (min-width: 1025px) {
  .ctc-sys.v1.bg-shf-r .img {
    width: calc(50% + calc(4.8% * 2));
    margin-left: auto;
    height: 100%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 501px) {
  .ctc-sys.v1 .loc-lst ul {
    display: flex;
    flex-wrap: wrap;
  }
  .ctc-sys.v1 .loc-lst ul li:nth-child(2) {
    margin-top: 0;
  }
  .ctc-sys.v1 .loc-lst ul li {
    margin-right: 1.8rem;
  }
}
@media screen and (max-width: 1024px) {
  .ctc-sys.v1 .bg-wrp {
    position: relative;
  }
  .ctc-sys.v1 .mp-bx .pd-h-::after {
    padding-bottom: 80%;
  }
}
.ftr-tls .loc-lst address + a {
  text-transform: uppercase;
}
.ftr-tls .search-bar .input-text {
  padding: 1rem;
}
.ftr-tls .search-bar .input-text label {
  position: absolute;
  transition: top 0.4s ease 0s, transform 0.4s ease 0s, opacity 0.25s ease 0s;
  left: 1rem;
}
.ftr-tls .input-text:focus label,
.ftr-tls .input-text.focused label {
  opacity: 0;
}
.ftr.v2 {
}
@media screen and (max-width: 1024px) {
  .ftr.v2 [class*="-lg"] {
    max-width: 60%;
  }
  .ftr.v2 .lt ul {
    display: flex;
    justify-content: space-evenly;
  }
}
.scp-ftr {
}
.scp-ftr .nv-dsp {
  display: block;
}
.scp-ftr.lt-bg {
  background-color: #ffffff;
}
.scp-ftr.lt-bg,
.scp-ftr.lt-bg a {
  color: #191919;
}
.scp-ftr.dk-bg {
  background-color: #191919;
  color: #86898f !important;
}
.scp-ftr.dk-bg,
.scp-ftr.dk-bg a {
  color: #86898f;
}
@media screen and (min-width: 501px) {
  .scp-ftr .inf {
    width: 66%;
  }
}
@media screen and (max-width: 500px) {
  .scp-ftr .sd-lg {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
.acc-opt.v1 {
}
.acc-opt.v1 .acc-mnu {
  display: block;
  position: fixed;
  z-index: 40;
  height: 100vh;
  top: 0;
  left: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: transform 0.3s ease 0s, visibility 0s ease 0.3s;
}
.lt-bg.acc-opt.v1 .acc-mnu {
  background-color: #ffffff;
}
html.high-contrast .lt-bg.acc-opt.v1 .acc-mnu {
  background-color: #ffffff;
}
.dk-bg.acc-opt.v1 .acc-mnu {
  background-color: #191919;
}
html.high-contrast .dk-bg.acc-opt.v1 .acc-mnu {
  background-color: #191919;
}
.opt-opn.acc-opt.v1 .acc-mnu {
  visibility: visible;
  transform: translateX(0);
  transition: transform 0.3s ease 0.3s, visibility 0s ease 0.3s;
}
.acc-opt.v1 .fltr {
  position: fixed;
  cursor: pointer;
  width: 50px !important;
  height: 50px !important;
  font-size: 50px;
  bottom: 1rem;
  right: 1rem;
  opacity: 1;
  z-index: 2;
  transform: translateY(0);
  transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;
}
.acc-opt.v1 .fltr.lt {
  right: auto;
  left: 1rem;
}
.acc-opt.v1 .fltr circle {
  fill: #0066ee;
}
.acc-opt.v1 .fltr path {
  fill: #ffffff;
}
.header-hide .acc-opt.v1 .fltr,
.opt-opn.acc-opt.v1 .fltr {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(1rem * 3));
}
.acc-opt.v1 li .acc-i {
  transition: none;
}
.lt-bg.acc-opt.v1 li .acc-i:not(:hover),
.lt-bg.acc-opt.v1 li .acc-i:not(:focus) {
  color: #a0a0a0;
}
html.high-contrast .lt-bg.acc-opt.v1 li .acc-i:not(:hover),
html.high-contrast .lt-bg.acc-opt.v1 li .acc-i:not(:focus) {
  color: #a0a0a0;
}
.dk-bg.acc-opt.v1 li .acc-i:not(:hover),
.dk-bg.acc-opt.v1 li .acc-i:not(:focus) {
  color: #d6dbdf;
}
html.high-contrast .dk-bg.acc-opt.v1 li .acc-i:not(:hover),
html.high-contrast .dk-bg.acc-opt.v1 li .acc-i:not(:focus) {
  color: #d6dbdf;
}
.lt-bg.acc-opt.v1 li button:not(:hover),
.lt-bg.acc-opt.v1 li button:not(:focus) {
  color: #191919;
}
html.high-contrast .lt-bg.acc-opt.v1 li button:not(:hover),
html.high-contrast .lt-bg.acc-opt.v1 li button:not(:focus) {
  color: #191919;
}
.dk-bg.acc-opt.v1 li button:not(:hover),
.dk-bg.acc-opt.v1 li button:not(:focus) {
  color: #ffffff;
}
html.high-contrast .dk-bg.acc-opt.v1 li button:not(:hover),
html.high-contrast .dk-bg.acc-opt.v1 li button:not(:focus) {
  color: #ffffff;
}
html.high-contrast .bg-wrp,
html.high-contrast .img-bg,
html.high-contrast .img-bg img {
  display: none !important;
}
html.x1 *,
html.x2 * {
  word-break: break-word !important;
}
html.x1 [class*="mn_"],
html.x2 [class*="mn_"] {
  max-width: 100% !important;
}
html.x1 [class*="flx"]:not([data-role="list"]),
html.x2 [class*="flx"]:not([data-role="list"]) {
  flex-wrap: wrap !important;
}
html.x1 [class*="flx"]:not([data-role="list"]) > *,
html.x2 [class*="flx"]:not([data-role="list"]) > * {
  flex: 1 0 auto !important;
}
html.x1 .acc-opt.v1 li button,
html.x2 .acc-opt.v1 li button {
  flex-wrap: nowrap !important;
}
html.x1 .acc-opt.v1 li button > *,
html.x2 .acc-opt.v1 li button > * {
  flex: 0 1 auto !important;
}
html.x1 [class*="pn-dco"]::before,
html.x2 [class*="pn-dco"]::before,
html.x1 [class*="pn-dco"]::after,
html.x2 [class*="pn-dco"]::after {
  display: none !important;
}
@media screen and (min-width: 1025px) {
  .acc-opt.v1 .fltr:hover,
  .acc-opt.v1 .fltr:focus {
    transform: scale(1.08, 1.08);
  }
}
:root {
}
#HeaderZone {
  z-index: 10;
  position: relative;
}
.hdr .tp-lg {
  max-width: calc((250 * 1px) + (500 - 250) * ((100vw - 320px) / (1920 - 320)));
}
.overlap #HeaderZone {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.overlap .hdr {
  background-color: transparent;
}
.hdr .mn_wd {
  padding-right: 0;
}
.hdr .tp-br,
.hdr .nv-bx {
  padding-right: calc(
    (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
  );
}
.hdr .tgl {
  font-style: italic;
}
.hdr.mnu-opn .lg-bx {
  z-index: 1 !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .hdr .tp-lg {
    max-width: 500px;
  }
}
.hdr .scd-nav a,
.hdr .nv ul li a {
  outline: none;
  line-height: 1.2;
  font-size: calc(1rem * 0.81);
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Georama", sans-serif;
  letter-spacing: 0.15rem;
}
.fly-nv {
  position: relative;
  width: 100%;
}
.hdr .fly-nav.active {
  transition: none;
}
.hdr .nv .el-tab {
  outline: none;
  font-size: 1.1em;
  transition: transform 0.3s ease 0s, color 0.3s ease 0s;
}
.hdr .nv .el-tab.active {
  transform: rotateX(180deg);
}
.hdr .sch-lnk {
  padding: 0.75em;
}
.hdr button.clr-link:hover,
.hdr button.clr-link:focus {
  color: #191919;
}
.hdr .mnu-btn {
  display: none;
  width: 4em;
  height: 4em;
  position: relative;
  cursor: pointer;
}
.hdr .mnu-btn span {
  position: absolute;
  width: 2.25em;
  height: 3px;
  top: 50%;
  left: 50%;
  transition: background-color 0.3s ease 0s;
  transform: translate(-50%, -50%);
}
.lt-bg.hdr .mnu-btn span,
.dk-bg.hdr .mnu-btn span {
  background-color: #ffffff;
}
.mnu-opn.lt-bg.hdr .mnu-btn span,
.mnu-opn.dk-bg.hdr .mnu-btn span {
  background-color: #a0a0a0;
}
.hdr .mnu-btn span:first-of-type {
  margin-top: 0.556em;
}
.hdr .mnu-btn span:last-of-type {
  margin-top: -0.556em;
}
.hdr .mnu-btn.mbl {
  position: fixed;
  top: 1em;
  left: 1em;
  z-index: 3;
  transition: transform 0.3s ease 0s;
  transform: translate(0, 0);
}
.lt-bg.hdr .mnu-btn.mbl > span,
.dk-bg.hdr .mnu-btn.mbl > span {
  background-color: #ffffff;
}
.header-hide:not(.mnu-opn) .hdr .mnu-btn.mbl,
.mnu-opn .hdr .mnu-btn.mbl {
  transform: translatex(
    calc(
      (101% + calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320)))) *
        -1
    )
  );
}
.hdr .mnu-nv li button svg {
  transition: color 0.3s ease 0s;
}
@media screen and (max-width: 1600px) and (min-width: 1281px) {
  .hdr .nv-bx .el-tab-box {
    max-width: calc(
      (750 * 1px) + (996 - 750) * ((100vw - 1280px) / (1600 - 1280))
    );
  }
}
@media screen and (max-width: 1280px) and (min-width: 801px) {
  .hdr .tp-br,
  .hdr .nv-bx {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 801px) {
  .hdr .lg-bx {
    position: absolute;
    top: 0;
    left: calc((20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320)));
    z-index: 3 !important;
    bottom: calc(
      calc((45 * 1px) + (30 - 45) * ((100vw - 800px) / (1920 - 800))) * -1
    );
  }
}
@media screen and (min-width: 1025px) {
  .lt-bg.hdr .nv .el-tab:hover,
  .lt-bg.hdr .nv .el-tab:focus {
    color: #a0a0a0;
  }
  .dk-bg.hdr .nv .el-tab:hover,
  .dk-bg.hdr .nv .el-tab:focus {
    color: #191919;
  }
  .mnu-opn.lt-bg.hdr .mnu-btn:hover span,
  .mnu-opn.lt-bg.hdr .mnu-btn:focus span {
    background-color: #ffffff;
  }
  .dk-bg.hdr .mnu-btn:hover span,
  .dk-bg.hdr .mnu-btn:focus span,
  .mnu-opn.dk-bg.hdr .mnu-btn:hover span,
  .mnu-opn.dk-bg.hdr .mnu-btn:focus span {
    background-color: #a1a4a7;
  }
  .dk-bg.hdr .mnu-nv > li button svg:hover,
  .dk-bg.hdr .mnu-nv > li button svg:focus {
    color: #ffffff;
  }
  .hdr .tp-br,
  .hdr .nv-bx {
    padding-right: calc(
      (20 * 1px) + (140 - 20) * ((100vw - 320px) / (1920 - 320))
    );
  }
  .dk-bg.hdr .bg-bx.lk-bg .el-tab.active svg,
  .dk-bg.hdr .bg-bx.lk-bg .el-tab:hover svg,
  .dk-bg.hdr .bg-bx.lk-bg .el-tab:focus svg {
    color: #ffffff;
  }
  .dk-bg.hdr .bg-bx.ulk-bg .el-tab.active svg,
  .dk-bg.hdr .bg-bx.ulk-bg .el-tab:hover svg,
  .dk-bg.hdr .bg-bx.ulk-bg .el-tab:focus svg {
    color: #191919;
  }
  .lt-bg.hdr .fly-nv.bg-bx.lk-bg a:hover,
  .lt-bg.hdr .fly-nv.bg-bx.lk-bg a:focus {
    color: #191919;
  }
}
@media screen and (min-width: 1281px) {
  .hdr .scd-nav + .bx {
    position: relative;
    padding-left: calc(1rem * 0.8);
  }
  .lt-bg.hdr .bg-bx.lk-bg .scd-nav + .bx::before {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .lt-bg.hdr .bg-bx.ulk-bg .scd-nav + .bx::before {
    background-color: rgba(255, 255, 255, 0.4);
  }
  .hdr .nv {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    visibility: visible;
  }
  .hdr .nv .mnu-nv,
  .hdr .nv .el-tab.lvl-1 {
    display: none;
  }
  .dk-bg.hdr .scd-nav ul > li:hover > a,
  .dk-bg.hdr .scd-nav ul > li:focus > a,
  .dk-bg.hdr .scd-nav ul > li.selected > a,
  .dk-bg.hdr .nv ul > li:hover > a,
  .dk-bg.hdr .nv ul > li:focus > a,
  .dk-bg.hdr .nv ul > li.selected > a {
    color: #a0a0a0;
  }
  .lt-bg.hdr .scd-nav ul > li:hover > a,
  .lt-bg.hdr .scd-nav ul > li:focus > a,
  .lt-bg.hdr .scd-nav ul > li.selected > a,
  .lt-bg.hdr .nv ul > li:hover > a,
  .lt-bg.hdr .nv ul > li:focus > a,
  .lt-bg.hdr .nv ul > li.selected > a {
    color: #a0a0a0;
  }
  .lt-bg.hdr .scd-nav > ul > li:hover > a,
  .lt-bg.hdr .scd-nav > ul > li:focus > a,
  .lt-bg.hdr .scd-nav > ul > li.selected > a {
    opacity: 1;
  }
  .dk-bg.hdr .scd-nav > ul > li:hover > a,
  .dk-bg.hdr .scd-nav > ul > li:focus > a,
  .dk-bg.hdr .scd-nav > ul > li.selected > a {
    opacity: 1;
  }
  .hdr .scd-nav > ul > li.selected > a,
  .hdr .nv > ul > li.selected > a {
    font-weight: bold;
  }
  .hdr .fly-nv {
    min-width: 20rem;
    position: absolute;
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    overflow: visible;
    display: block !important;
    transition: all 0.3s ease 0s !important;
  }
  .hdr .nv .mnu-nv + ul > li:nth-last-child(-n + 2) .fly-nv {
    left: auto;
    right: 0;
  }
  .hdr li:hover .fly-nv,
  .hdr .active .fly-nv {
    opacity: 1;
    visibility: visible !important;
    transition: all 0.3s ease 0s !important;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1281px) {
  .hdr .nv {
    padding-right: 0;
  }
  .hdr .cta {
    display: none;
  }
}
@media screen and (min-width: 1601px) {
}
@media screen and (max-width: 1280px) {
  .hdr .mnu-btn.dkp {
    display: block;
  }
  .hdr .nv {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 2;
    overflow: auto;
    padding-bottom: 4em;
    transform: translateX(-100%);
    transition: transform 0.3s ease 0s, visibility 0s ease 0.3s;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.2);
  }
  .lt-bg.hdr .nv {
    background-color: #ffffff;
  }
  .dk-bg.hdr .nv {
    background-color: #292929;
  }
  .mnu-opn .hdr .nv {
    transform: translateX(0);
    transition: transform 0.3s ease 0s, visibility 0s ease 0s;
  }
  .hdr .nv .el-panel {
    transition: visibility 0s ease 1s;
  }
  .hdr .nv .active.el-panel {
    transition: visibility 0s ease 0s;
  }
  .hdr .el-tab {
    padding-left: 0;
  }
  .hdr .nv > ul:not(.mnu-nv) {
    display: block;
  }
  .hdr .nv > ul {
    width: 94%;
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
  }
  .hdr .nv > ul:not(.mnu-nv) > li {
    margin-left: 0;
    margin-right: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .lt-bg.hdr .nv > ul:not(.mnu-nv) > li {
    border-color: rgba(0, 0, 0, 0.1);
  }
  .dk-bg.hdr .nv > ul:not(.mnu-nv) > li {
    border-color: rgba(255, 255, 255, 0.4);
  }
  .hdr .nv > ul > li > a {
    display: block;
    font-size: 1.444em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }
  .hdr .nv > ul > li > a span {
    padding-top: 0;
    padding-bottom: 0;
  }
  .hdr .nv .fly-nav {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hdr .tp-br.bg-bx::before {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .hdr .tp-lg {
    margin-left: auto;
    margin-right: auto;
  }
  .hdr .mnu-btn.dkp {
    display: none;
  }
  .hdr .mnu-btn.mbl {
    display: block;
  }
  .dk-bg.hdr .mnu-btn.mbl {
    background-color: #a0a0a0;
  }
  .hdr .nv-bx {
    padding: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .hdr .phn-lst {
    display: block;
  }
  .hdr .phn-lst .ln {
    display: none;
  }
  .hdr .phn-lst li + li {
    margin-top: calc(1rem * 0.5);
  }
}
.el-tab-box .el-tab {
  cursor: pointer;
}
.el-tab .tb-arw {
  transition: transform 0.3s ease 0s;
  transform-origin: 50% 50%;
}
.el-tab.active .tb-arw {
  transform: rotate(180deg);
}
html:not(.cms-content) .el-tab-box .el-tab-panel:not(.active),
html:not(.cms-content) .el-tab-box .el-panel:not(.active) {
  display: none;
  visibility: hidden;
}
.el-panel.sld {
  transition: height 0.5s ease 0s, margin 0.5s ease 0s, padding 0.5s ease 0s !important;
}
.mstg.v3 {
}
.mstg.v3 .psu-bfr::before {
  background: linear-gradient(to top, #000000, transparent);
  z-index: 1;
  opacity: 0;
}
.mstg.v3 .mn_wd {
  z-index: 2;
}
.mstg.v3 .inf {
  padding-top: calc((0 * 1px) + (250 - 0) * ((100vw - 320px) / (1920 - 320)));
  padding-bottom: calc(
    (50 * 1px) + (260 - 50) * ((100vw - 320px) / (1920 - 320))
  );
}
.mstg.v3 .inf span {
  margin-top: calc((30 * 1px) + (30 - 30) * ((100vw - 320px) / (1920 - 320)));
  margin-bottom: calc(
    (20 * 1px) + (21 - 20) * ((100vw - 320px) / (1920 - 320))
  );
  font-size: 2rem;
}
.mstg.v3 .img.pd-h-::after {
  padding-bottom: calc(
    (170 * 1px) + (880 - 170) * ((100vw - 320px) / (1920 - 320))
  );
}
@media screen and (max-width: 1024px) {
  .mstg.v3 .inf {
    padding-top: 0;
  }
}
[data-role="scroller"] [data-role="container"] {
  position: relative;
  overflow: hidden;
}
[data-role="scroller"] [data-role="list"] {
  flex-wrap: nowrap !important;
  position: relative;
  transition: transform 0.6s ease 0s, margin 0.6s ease 0s;
}
[data-role="scroller"] [data-role="item"] {
  flex: 0 0 auto !important;
  transition: opacity 0.6s ease 0s;
}
[data-role="scroller"].active:not(.tabbing)
  [data-role="item"].full:not(.active):not(.s-active) {
  opacity: 0;
}
[data-role="scroller"].start.end [data-role="arrows"],
[data-role="scroller"]:not(.active) [data-role="arrows"],
[data-role="scroller"].tabbing [data-role="arrows"] {
  display: none !important;
}
[data-role="scroller"].start.end ul[data-role="list"] {
  justify-content: center;
}
[data-role="scroller"] [data-role="arrows"] > *:nth-child(n + 2) {
  margin-left: 1rem;
}
[data-role="scroller"] [data-role="arrows"] svg {
  font-size: 1.4em;
  transition: color 0.3s ease 0s;
}
[data-role="scroller"] [data-role="paging"] {
  text-align: center;
  display: block;
}
@media screen and (max-width: 1600px) {
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].fifth {
    max-width: calc(25% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    > li.fifth {
    max-width: calc(25% - (1.8rem * 0.7));
  }
  [data-role="scroller"] [data-role="list"].sl_ato-rsp [data-role="item"].sixth,
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].seventh {
    width: 20%;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(20% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(20% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 1280px) {
  [data-role="scroller"] [data-role="list"].sl_itm-100-1280 [data-role="item"] {
    width: 100% !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_itm-100-1280
    [data-role="item"] {
    max-width: calc(100% - 1.8rem) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_itm-100-1280
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 0.7)) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_itm-100-1280
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 1.5)) !important;
  }
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].third {
    width: 50%;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].third {
    max-width: calc(50% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].third {
    max-width: calc(50% - (1.8rem * 0.7));
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_ato-rsp
    [data-role="item"].third {
    max-width: calc(50% - (1.8rem * 1.5));
  }
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].fourth,
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].fifth {
    width: 33.333%;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].fourth,
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].fifth {
    max-width: calc(33.333% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].fourth,
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].fifth {
    max-width: calc(33.333% - (1.8rem * 0.7));
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_ato-rsp
    [data-role="item"].fourth {
    max-width: calc(33.333% - (1.8rem * 1.5));
  }
  [data-role="scroller"] [data-role="list"].sl_ato-rsp [data-role="item"].sixth,
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].seventh {
    width: 25%;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(25% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(25% - (1.8rem * 0.7));
  }
}
@media screen and (min-width: 1025px) {
  [data-role="scroller"].sl_sa-edg {
    padding-left: 1.4em;
    padding-right: 1.4em;
  }
  [data-role="scroller"].sl_sa-edg [data-role="container"] {
    z-index: 2;
  }
  [data-role="scroller"].sl_sa-edg [data-role="arrows"] {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    justify-content: space-between;
    z-index: 1;
    margin-top: 0;
  }
  [data-role="scroller"].sl_sa-edg [data-role="arrows"] .sl_pg {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  [data-role="scroller"] [data-role="list"].sl_itm-100-1024 [data-role="item"] {
    width: 100% !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_itm-100-1024
    [data-role="item"] {
    max-width: calc(100% - 1.8rem) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_itm-100-1024
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 0.7)) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_itm-100-1024
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 1.5)) !important;
  }
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].fourth {
    width: 50%;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].fourth {
    max-width: calc(50% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].fourth {
    max-width: calc(50% - (1.8rem * 0.7));
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_ato-rsp
    [data-role="item"].fourth {
    max-width: calc(50% - (1.8rem * 1.5));
  }
  [data-role="scroller"] [data-role="list"].sl_ato-rsp [data-role="item"].sixth,
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].seventh {
    width: 33.333%;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(33.333% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(33.333% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 800px) {
  [data-role="scroller"] [data-role="list"].sl_itm-100-800 [data-role="item"] {
    width: 100% !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_itm-100-800
    [data-role="item"] {
    max-width: calc(100% - 1.8rem) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_itm-100-800
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 0.7)) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_itm-100-800
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 1.5)) !important;
  }
  [data-role="scroller"] [data-role="list"].sl_ato-rsp [data-role="item"].fifth,
  [data-role="scroller"] [data-role="list"].sl_ato-rsp [data-role="item"].sixth,
  [data-role="scroller"]
    [data-role="list"].sl_ato-rsp
    [data-role="item"].seventh {
    width: 50%;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].fifth,
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(50% - 1.8rem);
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].fifth,
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_ato-rsp
    [data-role="item"].sixth {
    max-width: calc(50% - (1.8rem * 0.7));
  }
}
@media screen and (max-width: 500px) {
  [data-role="scroller"] [data-role="list"].sl_itm-100-500 [data-role="item"] {
    width: 100% !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_itm-100-500
    [data-role="item"] {
    max-width: calc(100% - 1.8rem) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_itm-100-500
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 0.7)) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_itm-100-500
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 1.5)) !important;
  }
}
@media screen and (max-width: 380px) {
  [data-role="scroller"] [data-role="list"].sl_itm-100-380 [data-role="item"] {
    width: 100% !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-lrg"].sl_itm-100-380
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 1.5)) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"].sl_itm-100-380
    [data-role="item"] {
    max-width: calc(100% - 1.8rem) !important;
  }
  [data-role="scroller"]
    [class*="-grd"][data-role="list"][class*="-sml"].sl_itm-100-380
    [data-role="item"] {
    max-width: calc(100% - (1.8rem * 0.7)) !important;
  }
}
.srv.v3 {
}
.srv.v3 .srv-lst .srv-itm:not(:first-of-type):last-child {
  margin-top: calc((1.8rem * 0.7) - 0.111rem);
}
.srv.v3 .srv-lst li svg {
  font-size: 2.7rem;
  margin: 0.5rem;
}
.srv-tls .icn-bx {
  background-color: #292929;
}
@media screen and (max-width: 500px) {
  .srv-tls .srv-lst li > .icn-bx {
    width: min-content !important;
    margin: auto !important;
  }
}
.cnt.v1 {
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cnt.v1 .psu-bfr::before {
    left: 51.5% !important;
  }
}
@media screen and (min-width: 1025px) {
  .cnt.v1 .psu-bfr::before {
    width: 1px;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
  }
  .dk-bg.cnt.v1 .psu-bfr::before {
    background-color: #ffffff;
  }
  .lt-bg.cnt.v1 .psu-bfr::before {
    background-color: #000000;
  }
}
.vls-tls {
}
.vls-tls .icn-bx {
  background-color: #292929;
}
.vls-tls.ctc .icn-bx {
  background-color: #191919;
}
.vls-tls .vls-lst li svg {
  font-size: 2.7rem;
  margin: 0.5rem;
}
.vls-tls .vls-lst .cnt-stl {
  line-height: calc(2.23 * 0.7);
}
.dk-bg.vls-tls .bg-bx.ulk-bg .fnt_tc-5 {
  color: #292929;
}
@media screen and (max-width: 500px) {
  .vls-tls .vls-lst li > .icn-bx {
    width: min-content !important;
    margin: auto !important;
  }
}
[class*="flx"][class*="-grd"][class*="-lrg"] {
  width: calc(100% + (1.8rem * 1.5));
  left: calc((1.8rem * 1.5) * -0.5);
  margin-top: calc((1.8rem * 1.5) * -0.5);
  margin-bottom: calc((1.8rem * 1.5) * -0.5);
}
[class*="flx"][class*="-grd"][class*="-lrg"] > li {
  margin: calc(((1.8rem * 1.5) - 0.111rem) * 0.5);
}
[class*="flx"][class*="-grd"][class*="-lrg"] > li.full {
  max-width: calc(100% - (1.8rem * 1.5));
}
[class*="flx"][class*="-grd"][class*="-lrg"] > li.half {
  max-width: calc(50% - (1.8rem * 1.5));
}
[class*="flx"][class*="-grd"][class*="-lrg"] > li.third {
  max-width: calc(33.333% - (1.8rem * 1.5));
}
[class*="flx"][class*="-grd"][class*="-lrg"] > li.fourth {
  max-width: calc(25% - (1.8rem * 1.5));
}
[class*="ato-sz"][class*="flx"][class*="-grd"][class*="-lrg"] > li {
  width: calc(14.285% - (1.8rem * 1.5));
}
[class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]
  > li:nth-last-of-type(2n):first-of-type,
[class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]
  > li:nth-last-of-type(2n):first-of-type
  ~ li {
  width: calc(50% - (1.8rem * 1.5));
}
@media screen and (min-width: 1025px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-4"][class*="-lrg"]
    > li {
    width: calc(25% - (1.8rem * 1.5));
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-3"]
    )
    > li:nth-last-of-type(4n):first-of-type
    ~ li {
    width: calc(25% - (1.8rem * 1.5));
  }
}
@media screen and (min-width: 1281px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-3"][class*="-lrg"]
    > li {
    width: calc(33.333% - (1.8rem * 1.5));
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]
    > li:nth-last-of-type(3n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]
    > li:nth-last-of-type(3n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]
    > li:nth-last-of-type(3n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]
    > li:nth-last-of-type(3n):first-of-type
    ~ li {
    width: calc(33.333% - (1.8rem * 1.5));
  }
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="mx-5"][class*="-lrg"]
    > li {
    width: calc(20% - (1.8rem * 1.5));
  }
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-4"]
    ):not([class*="mx-3"])
    > li:nth-last-of-type(5n):first-of-type
    ~ li {
    width: calc(20% - (1.8rem * 1.5));
  }
}
@media screen and (min-width: 1601px) {
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 2):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n - 1):first-of-type
    ~ li,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type,
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"]:not(
      [class*="mx-5"]
    ):not([class*="mx-4"]):not([class*="mx-3"])
    > li:nth-last-of-type(6n):first-of-type
    ~ li {
    width: calc(16.666% - (1.8rem * 1.5));
  }
}
@media screen and (max-width: 1600px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-lrg"] > li {
    width: calc(20% - (1.8rem * 1.5));
  }
}
@media screen and (max-width: 1440px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-lrg"] > li {
    width: calc(25% - (1.8rem * 1.5));
  }
}
@media screen and (max-width: 1280px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-lrg"] > li {
    width: calc(33.333% - (1.8rem * 1.5));
  }
}
@media screen and (max-width: 1024px) {
  [class*="ato-sz"][class*="flx"][class*="-grd"][class*="-lrg"] > li {
    width: calc(50% - (1.8rem * 1.5));
  }
}
@media screen and (max-width: 800px) {
  [class*="ato-sz"][class*="flx-"][class*="-grd"][class*="-lrg"] > li:not(#_) {
    width: calc(50% - (1.8rem * 1.5));
  }
}
.bdr_a {
  border-width: 1px;
  border-style: solid;
}
.bdr_t {
  border-top-width: 1px;
  border-top-style: solid;
}
.bdr_b {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bdr_l {
  border-left-width: 1px;
  border-left-style: solid;
}
.bdr_r {
  border-right-width: 1px;
  border-right-style: solid;
}
.cta.v1 {
}
.cta.v1 .mn_wd {
  z-index: 3;
}
.cta.v1 .cta-lst .icn-bx {
  padding: 1.1rem;
}
.cta.v1 .cta-lst .icn {
  font-size: 2.1rem;
}
.mstg + .cta.v1 {
  margin-top: calc(
    -1 * calc((90 * 1px) + (208 - 90) * ((100vw - 320px) / (1920 - 320)))
  );
}
.lt-bg.cta.v1 .icn {
  color: #191919;
}
@media screen and (min-width: 801px) {
  .cta.v1 .cta-lst strong {
    width: 60%;
  }
}
@media screen and (max-width: 1024px) {
  .mstg + .cta.v1 .mn_wd {
    padding-top: 0 !important;
  }
}
:root {
}
.mstg.v3 {
}
